import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import CardHeading from '../../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../../../components/core/form/TextInputRT';
import FormActions from '../../../../../components/form/FormActions';
import { constants, icons } from '../../../../../constants';
import { globals, ToastAlert, validations } from '../../../../../utils';
import FileUploadInput from '../../../../../components/core/form/FileUploadInput';
import { ClientServices } from '../../../../../services';

const UpdateClientPagePopup = ({user,data,concludeUpdateClientPopUp}) => {
    console.log("client update",data)
    const navigate = useNavigate();

    const [name, setName] = useState(data === null ? 0 : data.name);
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [url, setURL] = useState(data === null ? 0 : data.url);
    const [hasURLIcon, setHasURLIcon] = useState(false);
    const [urlIcon, setURLIcon] = useState("");
    const [urlIconPosition, setURLIconPosition] = useState("right");
    const [urlIconType, setURLIconType] = useState("");
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Logo");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const nameChanged = async (e) => {
        const isNameValid = await validations.validateAllName(e);
        if(isNameValid.status){
            setName(e);
        }
        else if(e.length === 0){
            setName('');
        }
    }
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateClientSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        const isNameValid = await validations.validateAllName(name);

        if(isNameValid.status === false){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(data===null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }
    
            if(isNameValid.status === false || selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const updateClientReq = await ClientServices.updateClient({
                    id:data.id,
                    name: name,
                    url: url,
                    image: selectedImages[0],
                    token: user.access_token
                });
    
                if(updateClientReq.status === false){
                    ToastAlert.notifyError(updateClientReq.message);
                    setSubmitButtonLoading(false);
                    setHasNameIcon(false);
                    setNameIcon("");
                    setNameIconType("");
                }
                else{
                    ToastAlert.notifySuccess(updateClientReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonHasIcon(true);
                    setSubmitButtonLable('Success');
                    concludeUpdateClientPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            const updateClientReq = await ClientServices.updateClient({
                id:data.id,
                name: name,
                url: url,
                image:selectedImages.length > 0 ? selectedImages[0] : data.image._id,
                token: user.access_token
            });

            if(updateClientReq.status === false){
                ToastAlert.notifyError(updateClientReq.message);
                setSubmitButtonLoading(false);
                setHasNameIcon(false);
                setNameIcon("");
                setNameIconType("");
            }
            else{
                ToastAlert.notifySuccess(updateClientReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                concludeUpdateClientPopUp({
                    status: true
                })
            }
        }
        }
        
    return (
        <>
            <PlainContainer type='full' styles={{padding: "30px"}}>
                <form onSubmit={(e) => handleUpdateClientSubmission(e)} noValidate>
                    <div className="cmsForm">
                        <SplitContainer>
                            <TextInputRT 
                                hasLable={true}
                                lable="Name"
                                isMandatory={true}
                                hasIcon={hasNameIcon}
                                icon={nameIcon}
                                iconPosition={nameIconPosition}
                                iconType={nameIconType}
                                hasMessage={hasNameMessage}
                                message={nameMessage}
                                messageType={nameMessageType}
                            >
                                <input 
                                    type="text" 
                                    className="cmsFormStepInputText" 
                                    placeholder={"Enter Name"}
                                    onChange={(e) => nameChanged(e.target.value)}
                                    value={name}
                                />
                            </TextInputRT>
                            <TextInputRT 
                                hasLable={true}
                                lable="URL"
                                isMandatory={true}
                                hasIcon={hasURLIcon}
                                icon={urlIcon}
                                iconPosition={urlIconPosition}
                                iconType={urlIconType}
                                hasMessage={hasURLMessage}
                                message={urlMessage}
                                messageType={urlMessageType}
                            >
                                <input 
                                    type="text" 
                                    className="cmsFormStepInputText" 
                                    placeholder={"Enter URL"}
                                    onChange={(e) => setURL(e.target.value)}
                                    value={url}
                                />
                            </TextInputRT>
                        </SplitContainer>
                        <FileUploadInput 
                            data={imageData}
                            user={user} 
                            lable={imagePopUpLable} 
                            acceptedFileType={imageType} 
                            isPreview={data === null ? false : true}
                            previewType={"image"}
                            preview={`${globals.API_BASE_URL}${data?.image?.image_url?.full?.high_res}`}
                            isMultiple={imageIsMultiple} 
                            maxSelection={imageMaxFileSelection} 
                            isError={imageIsError}
                            action={(e) => handleImageSelection(e)} 
                        />
                        <FormActions>
                            <Button 
                                type="submit"
                                bgType="fill"
                                width="auto"
                                bg={submitButtonBg}
                                borderRadius="short"
                                hasIcon={submitButtonHasIcon}
                                iconPosition={submitButtonIconPosition}
                                icon={submitButtonIco}
                                disabled={submitButtonDisabled}
                                isLoading={submitButtonLoading}
                            >
                                {submitButtonLable}
                            </Button>
                            <Button 
                                type="button"
                                bgType="fill"
                                width="auto"
                                bg={cancelButtonBg}
                                borderRadius="short"
                                action={() => concludeUpdateClientPopUp({
                                    status: false
                                })}
                            >
                                {cancelButtonLable}
                            </Button>
                        </FormActions>
                    </div>
                </form>
            </PlainContainer>
        </>
    )
}

export default UpdateClientPagePopup