import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import {ContactUsPageServices } from '../../../../../services';

const UpdateContactPageLocationsPopUp = ({ user, location_section, concludeUpdateSectionHeadingsPopUp }) => {
    
    console.log("locationpop up update",location_section)

    const [title, setTitle] = useState(location_section !== null  ? location_section.name: "");
    const [hasTitleIcon, setHasTitleIcon] = useState(false);
    const [titleIcon, setTitleIcon] = useState("");
    const [titleIconPosition, setTitleIconPosition] = useState("right");
    const [titleIconType, setTitleIconType] = useState("");
    const [hasTitleMessage, setHasTitleMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState("");
    const [titleMessageType, setTitleMessageType] = useState("");

    const [address, setAddress] = useState(location_section !== null  ? location_section.address: "");
    const [hasAddressMessage, setHasAddressMessage] = useState(false);
    const [addressMessage, setAddressMessage] = useState("");
    const [addressMessageType, setAddressMessageType] = useState("");

    const [phoneNumber, setPhoneNumber] = useState(location_section !== null  ? location_section.phone_number: "");
    const [hasPhoneNumberMessage, setHasPhoneNumberMessage] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
    const [phoneNumberMessageType, setPhoneNumberMessageType] = useState("");

    const [emailID, setEmailID] = useState(location_section !== null  ? location_section.email_id: "");
    const [hasEmailIDMessage, setHasEmailIDMessage] = useState(false);
    const [emailIDMessage, setEmailIDMessage] = useState("");
    const [emailIDMessageType, setEmailIDMessageType] = useState("");

    const [url, setURL] = useState(location_section !== null  ? location_section.map_url: "");
    const [hasURLIcon, setHasURLIcon] = useState(false);
    const [urlIcon, setURLIcon] = useState("");
    const [urlIconPosition, setURLIconPosition] = useState("right");
    const [urlIconType, setURLIconType] = useState("");
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(address.trim() === "" || title.trim()===""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveContactLocationReq = await ContactUsPageServices.updateContactLocation({
                id:location_section.id,
                name: title,
                address: address,
                phone_number:phoneNumber,
                email_id:emailID,
                map_url:url,
                token: user.access_token
            });

            if(saveContactLocationReq.status === false){
                ToastAlert.notifyError(saveContactLocationReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveContactLocationReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSectionHeadingsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingsSubmission(e)} noValidate>
                <div className="cmsForm">
                   
                <TextInputRT 
                                        hasLable={true}
                                        lable="Title"
                                        isMandatory={false}
                                        hasIcon={hasTitleIcon}
                                        icon={titleIcon}
                                        iconPosition={titleIconPosition}
                                        iconType={titleIconType}
                                        hasMessage={hasTitleMessage}
                                        message={titleMessage}
                                        messageType={titleIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Name"}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </TextInputRT>

                                    <TextInputRT 
                        hasLable={inputs.footerContactUsAddress.hasLable}
                        lable={"Enter Address"}
                        isMandatory={inputs.footerContactUsAddress.is_mandatory}
                        hasMessage={hasAddressMessage}
                        message={addressMessage}
                        messageType={addressMessageType}
                        isLimited={inputs.footerContactUsAddress.is_limited}
                        limit={inputs.footerContactUsAddress.max_characters}
                        value={address}
                    >
                        <input 
                            type={inputs.footerContactUsAddress.type} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter Address"}
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerContactUsPhoneNumber.hasLable}
                        lable={"enter Phone Number"}
                        isMandatory={inputs.footerContactUsPhoneNumber.is_mandatory}
                        hasMessage={hasPhoneNumberMessage}
                        message={phoneNumberMessage}
                        messageType={phoneNumberMessageType}
                        value={phoneNumber}
                    >
                        <input 
                            type={inputs.footerContactUsPhoneNumber.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsPhoneNumber.placeholder}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerContactUsEmailID.hasLable}
                        lable={"enter email id"}
                        isMandatory={inputs.footerContactUsEmailID.is_mandatory}
                        hasMessage={hasEmailIDMessage}
                        message={emailIDMessage}
                        messageType={emailIDMessageType}
                        value={emailID}
                    >
                        <input 
                            type={inputs.footerContactUsEmailID.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsEmailID.placeholder}
                            onChange={(e) => setEmailID(e.target.value)}
                            value={emailID}
                        />
                    </TextInputRT>
                    <TextInputRT 
                                            hasLable={true}
                                            lable="URL"
                                            isMandatory={true}
                                            hasIcon={hasURLIcon}
                                            icon={urlIcon}
                                            iconPosition={urlIconPosition}
                                            iconType={urlIconType}
                                            hasMessage={hasURLMessage}
                                            message={urlMessage}
                                            messageType={urlMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter URL"}
                                                onChange={(e) => setURL(e.target.value)}
                                                value={url}
                                            />
                                        </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateContactPageLocationsPopUp