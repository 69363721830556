import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { ArticlePageServices, ProductServices } from '../../../../services';

const UpdateArticleActionPopUp = ({ user, blog_id, action_data, concludeUpdateActionPopUp }) => {

    const [name, setName] = useState(action_data.name);
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [action, setAction] = useState(action_data.action);
    const [hasActionMessage, setHasActionMessage] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [actionMessageType, setActionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateActionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(name.trim() === ""){
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(action.trim() === ""){
            setHasActionMessage(true);
            setActionMessage("Invalid Action");
            setActionMessageType("error");
        }
        else{
            setHasActionMessage(false);
            setActionMessage("");
            setActionMessageType("");
        }

        if(name.trim() === "" || action.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const updateActionReq = await ArticlePageServices.updateAction({
                blog_id: blog_id,
                action_id: action_data.id,
                name: name,
                action: action,
                token: user.access_token
            });

            if(updateActionReq.status === false){
                ToastAlert.notifyError(updateActionReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateActionReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateActionPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateActionSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.productActionName.hasLable}
                        lable={inputs.productActionName.lable}
                        isMandatory={inputs.productActionName.is_mandatory}
                        hasMessage={hasNameMessage}
                        message={nameMessage}
                        messageType={nameMessageType}
                        isLimited={inputs.productActionName.is_limited}
                        limit={inputs.productActionName.max_characters}
                        value={name}
                    >
                        <input 
                            type={inputs.productActionName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.productActionName.placeholder}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </TextInputRT>
                    <TextInputRT 
                        hasLable={inputs.productActionAction.hasLable}
                        lable={inputs.productActionAction.lable}
                        isMandatory={inputs.productActionAction.is_mandatory}
                        hasMessage={hasActionMessage}
                        message={actionMessage}
                        messageType={actionMessageType}
                        isLimited={inputs.productActionAction.is_limited}
                        limit={inputs.productActionAction.max_characters}
                        value={action}
                    >
                        <input 
                            type={inputs.productActionAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.productActionAction.placeholder}
                            onChange={(e) => setAction(e.target.value)}
                            value={action}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateActionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateArticleActionPopUp