import React, { useState } from 'react'
import TextInputRT from '../../core/form/TextInputRT'
import { icons, inputs } from '../../../constants';
import Form from '../../form/Form';
import Button from '../../core/dashboard/Button';
import FormActions from '../../form/FormActions';
import { ToastAlert, statusCodes, validations } from '../../../utils';
import { FolderServices } from '../../../services';
import FileServices from '../../../services/Media/File';

const 

UpdateFilePopUp = ({ user,file_details,filename, concludeUpdateFilePopUp }) => {

    console.log("file details pop up",file_details)

    const [fileName, setFileName] = useState(file_details?.name?.current);
    const [hasFileNameMessage, setHasFileNameMessage] = useState(false);
    const [fileNameMessage, setFileNameMessage] = useState("");
    const [fileNameMessageType, setFileNameMessageType] = useState("");

    const [altText, setAltText] = useState(file_details?.name?.original);
    const [hasAltTextMessage, setHasAltTextMessage] = useState(false);
    const [altTextMessage, setAltTextMessage] = useState("");
    const [altTextMessageType, setAltTextMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const [fileNameAlreadyExist, setFileNameAlreadyExist] = useState(false);

    const handleFileNameChanged = async (e, input) => {
        if(e.length > 0){
            const isFileNameValid = await validations.validateFileName(e);
            if(isFileNameValid.status === true){
                setFileName(e);

                const checkFileNameAvailabilityReq = await FileServices.checkFileNameAvailability({
                    name: e,
                    file: file_details?._id,
                    token: user.access_token
                });

                if(checkFileNameAvailabilityReq.status === false){
                    if(checkFileNameAvailabilityReq.status_code === 403){
                        setHasFileNameMessage(true);
                        setFileNameMessage("File with same name already exists.");
                        setFileNameMessageType("error");
                        setSubmitButtonDisabled(true);
                        setFileNameAlreadyExist(true);
                    }
                    else{
                        ToastAlert.notifyError(checkFileNameAvailabilityReq.message);
                        setSubmitButtonLoading(false);
                        setSubmitButtonDisabled(false);
                        setFileNameAlreadyExist(false);
                    }
                }
                else{
                    setHasFileNameMessage(false);
                    setFileNameMessage("");
                    setFileNameMessageType("");
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(false);
                    setFileNameAlreadyExist(false);
                }
            }
            
        }
        else{
            setFileName(e);
            setSubmitButtonDisabled(false);
        }
    }

    const handleAltTextChanged = (e) => {
        setAltText(e);
        setSubmitButtonDisabled(!fileName || fileNameAlreadyExist || !altText.trim());
    }
    
    const handleSubmitUpdateFolder = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);
        setSubmitButtonDisabled(true);

        if(fileName.length <= inputs.folderName.max_characters){
            if(fileNameAlreadyExist === false){
                const isFileNameValid = await validations.validateFileName(fileName);
                if(isFileNameValid.status === true){
                    const createNewFileReq = await FileServices.UpdateFile({
                        id: file_details?._id,
                        name: fileName.toLowerCase().trimStart().trimEnd(),
                        altText : altText,
                        token: user.access_token
                    });

                    if(createNewFileReq.status === false){
                        ToastAlert.notifyError(createNewFileReq.message);
                        setSubmitButtonLoading(false);
                    }
                    else{
                        ToastAlert.notifySuccess(createNewFileReq.message);
                        setSubmitButtonLoading(false);
                        concludeUpdateFilePopUp({
                            status: true
                        });
                    }
                }
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <Form action={(e)=> handleSubmitUpdateFolder(e)} >
                <TextInputRT 
                    hasLable={inputs.folderName.hasLable}
                    lable={inputs.folderName.lable}
                    isMandatory={inputs.folderName.is_mandatory}
                    hasMessage={hasFileNameMessage}
                    message={fileNameMessage}
                    messageType={fileNameMessageType}
                    isLimited={inputs.folderName.is_limited}
                    limit={inputs.folderName.max_characters}
                    value={fileName}
                >
                    <input 
                        type={inputs.folderName.type} 
                        className="cmsFormStepInputText" 
                        placeholder={inputs.folderName.placeholder}
                        onChange={(e) => handleFileNameChanged(e.target.value)}
                        value={fileName}
                    />
                </TextInputRT>

                <TextInputRT 
                    hasLable={true}
                    lable={"Alt Text"}
                    isMandatory={true}
                    hasMessage={hasAltTextMessage}
                    message={altTextMessage}
                    messageType={altTextMessageType}
                    value={altText}
                >
                    <input 
                        type={"text"} 
                        className="cmsFormStepInputText" 
                        placeholder={"Edit Alt Text"}
                        onChange={(e) => handleAltTextChanged(e.target.value)}
                        value={altText}
                    />
                </TextInputRT>

                <FormActions>
                    <Button 
                        type="submit"
                        bgType="fill"
                        width="auto"
                        bg={submitButtonBg}
                        borderRadius="short"
                        disabled={submitButtonDisabled}
                        isLoading={submitButtonLoading}
                    >
                        {submitButtonLable}
                    </Button>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg={cancelButtonBg}
                        borderRadius="short"
                        action={() => concludeUpdateFilePopUp({
                            status: false
                        })}
                    >
                        {cancelButtonLable}
                    </Button>
                </FormActions>
            </Form>
                    
        </div>
    )
}

export default UpdateFilePopUp