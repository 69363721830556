import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import { ToastAlert } from '../../../utils';
import Pagination from '../../../components/core/table/pagination';
import { LeadsServices } from '../../../services';

const RequestCallbackListPage = ({ user }) => {
    const navigate = useNavigate();

    const [requestCallBack, setRequestCallBack] = useState([]);
    const [totalRequestCallBackCount, setTotalRequestCallBackCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetRequestCallBackRequest = async () => {
        const getRequestCallBackReq = await LeadsServices.getAllRequestCallbacks({
            token: user.access_token,
            current_page: currentPage
        });

        if(getRequestCallBackReq.status === false){
            ToastAlert.notifyError(getRequestCallBackReq.message);
            setRequestCallBack([]);
            setTotalRequestCallBackCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else {
            const Callbacks = getRequestCallBackReq?.data?.items?.map(cb => ({
                ...cb,
                createdAtFormatted: moment(cb.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setRequestCallBack(Callbacks);
            setTotalRequestCallBackCount(getRequestCallBackReq.data.total_items);
            setTotalPagesCount(getRequestCallBackReq.data.total_pages);
            setCurrentPage(getRequestCallBackReq.data?.current_page === undefined ? 1 : getRequestCallBackReq.data.current_page);;
        }
    }

    useEffect(() => {
        handleGetRequestCallBackRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading=' Callback Request'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Requested Callbacks .'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <StatesListFilters />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"person name"} />
                                    <TableHeadingItem size={2} lable={"organization name"} />
                                    <TableHeadingItem size={2} lable={"phone number"} />
                                    <TableHeadingItem size={2} lable={"Recived on"} />
                                    {/* <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} /> */}
                                </TableHeading>
                                <TableRows>
                                    {
                                        requestCallBack.map((cb, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.person_name} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.org_name} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.phone_number} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.createdAtFormatted} />
                                                    
                                                    {/* <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
                                                        </TableRowIcons>
                                                    </TableRowItem> */}
                                                    {/* <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions> */}
                                                            {/* <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${state.id}`) }>edit</TableButton> */}
                                                            {/* <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteStatesRequest(state.id)}>delete</TableButton> */}
                                                        {/* </TableRowActions>
                                                    </TableRowItem> */}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                            <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                            </PlainContainer>
                            :
                            <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default RequestCallbackListPage