import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { ProductServices, UniformsPageServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateUniformsPageSitemapPopUp from '../../../components/dashboard/popups/Pages/UniformsPage/UpdateUniformsPageSitemapPopUp';

const UniformsPage = ({ user }) => {
	const navigate = useNavigate();

	const [seo, setSEO] = useState(null);

	const handleGetSEORequest = async () => {
		const getSEOReq = await UniformsPageServices.getAuthSEO({
			token: user.access_token,
		});

		if (getSEOReq.status === false) {
			ToastAlert.notifyError(getSEOReq.message);
			setSEO(null);
		} 
		else {
			console.log(getSEOReq.data);
			setSEO(getSEOReq.data);
		}
	};

	useEffect(() => {
		handleGetSEORequest();
	}, []);

	const [sitemap, setSitemap] = useState(null);

	const handleGetSitemapRequest = async () => {
		const getSitemapReq = await UniformsPageServices.getAuthSitemap({
			token: user.access_token,
		});

		if (getSitemapReq.status === false) {
			ToastAlert.notifyError(getSitemapReq.message);
			setSitemap(null);
		} 
		else {
			console.log(getSitemapReq.data);
			setSitemap(getSitemapReq.data);
		}
	};

	useEffect(() => {
		handleGetSitemapRequest();
	}, []);

  	const [showUpdateSitemapPopUp, setShowUpdateSitemapPopUp] = useState(false);

    const handleConcludeUpdateSitemapPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSitemapPopUp(false);
        }
        else{
            setShowUpdateSitemapPopUp(false);
            handleGetSitemapRequest();
        }
    }

    const [products, setProducts] = useState([]);

    const handleGetProductsRequest = async () => {
        const getProductsReq = await ProductServices.getAllAuthProducts({
            token: user.access_token
        });

        if(getProductsReq.status === false){
            ToastAlert.notifyError(getProductsReq.message);
            setProducts([]);
        }
        else{
            console.log(getProductsReq.data);
            setProducts(getProductsReq.data);
        }
    }

    useEffect(() => {
        handleGetProductsRequest();
    }, []);

	const handleDeleteUniformProducts = async (action_id) => {
        console.log(action_id);
        const deleteUniformProductsReq = await UniformsPageServices.deleteUniformProducts({
            id:action_id,
            token: user.access_token
        });

        if(deleteUniformProductsReq.status === false){
            ToastAlert.notifyError(deleteUniformProductsReq.message);
            setProducts([]);
        }
        else{
            handleGetProductsRequest()
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);
    
    const SortableItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value?.product?.image?.image_url?.thumbnail?.low_res}`} lable={value?.product?.name} />
            <TableRowItem size={1} isCenter={false} lable={`${value.created_by.first_name} (${value.created_by.role === "SUPER_ADMIN" ? "super admin" : "admin"})`} />
            <TableRowItem size={0} isCenter={true} hasChildren={true}>
                <TableRowIcons>
                    <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`product/${value?.id}`)} />
                </TableRowIcons>
            </TableRowItem>
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={()=>navigate(`product/edit/${value?.id}`)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteUniformProducts(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
      
    const SortableContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const handleSaveSortedList = async (list) => {

        const sortProductReq = await ProductServices.sortProduct({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortProductReq.status === false){
            ToastAlert.notifyError(sortProductReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortProductReq.message);
            handleGetProductsRequest();
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(products, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setProducts([...arrayMove]);
    }


	return (
		<>
			<DefaultHeader>
				<DefaultContent
				heading="Uniforms Page"
				descriptionIcon={""}
				hasDescriptionIcon={false}
				description="Manage everything related to Uniforms page in this page"
				/>
				{/* <HeaderActions>
							<Button 
								type="button"
								bgType="fill"
								width="auto"
								bg="dashboard"
								borderRadius="full"
								hasIcon={true}
								iconPosition="left"
								icon={icons.add}
								action={()=> navigate('add') }
							>
								Add New
							</Button>
						</HeaderActions> */}
			</DefaultHeader>

			<PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
				<Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("seo")} isBottomAction={false}>
					{
						seo === null
						?
						<>
							<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
								<NoDataContainer
									icon={icons.sad}
									heading={"No SEO Details found!"}
									description={"Update seo details by clicking on the below button."}
									hasSectionAction={false}
									sectionAction={() => alert("section add")}
									hasActions={true}
									hasPrimaryAction={true}
									primaryActionLable={"update"}
									primaryAction={() => navigate("seo")}
									hasSecondaryAction={true}
									secondaryActionLable={"refresh"}
									secondaryAction={() => handleGetSEORequest("refresh")}
								/>
							</PlainContainer>
						</>
						:
						<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
							{
								seo?.title != ""
								?
								<DetailsRow>
									<DetailsItem 
										hasIcon={false} 
										icon={``} 
										lable={"title"} 
										description={defaults.capitalize(seo?.title)} 
									/>
								</DetailsRow>
								:
								<></>
							}
							{
								seo?.description != ""
								?
								<DetailsRow>
									<DetailsItem 
										hasIcon={false} 
										icon={``} 
										lable={"description"} 
										description={defaults.capitalize(seo?.description)} 
									/>
								</DetailsRow>
								:
								<></>
							}
							{
								seo?.keywords != ""
								?
								<DetailsRow>
									<DetailsItem 
										hasIcon={false} 
										icon={``} 
										lable={"keywords"} 
										description={defaults.capitalize(seo?.keywords)} 
									/>
								</DetailsRow>
								:
								<></>
							}
							{
								seo?.canonical_url != ""
								?
								<DetailsRow>
									<DetailsItem 
										hasIcon={false} 
										icon={``} 
										lable={"canonical URL"} 
										description={defaults.capitalize(seo?.canonical_url)} 
									/>
								</DetailsRow>
								:
								<></>
							}
							{
								seo?.twitter_handler != ""
								?
								<DetailsRow>
									<DetailsItem 
										hasIcon={false} 
										icon={``} 
										lable={"twitter handler"} 
										description={defaults.capitalize(seo?.twitter_handler)} 
									/>
								</DetailsRow>
								:
								<></>
							}
							{
								seo?.image != undefined
								?
								<DetailsRow>
									<DetailsItemWithPreview 
										lable={`Image`}
										preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
										previewType={"image"}
										fileName={seo?.image?.name?.original}
										fileExtension={seo?.image?.extension?.original}
										fileSize={seo?.image?.size?.current}
									/>
								</DetailsRow>
								:
								<></>
							}
						</PlainContainer>
					}
				</Card>
				<PlainContainer styles={{marginTop: "25px"}}>
					<Card hasHeading={true} heading={"Sitemap"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSitemapPopUp(true)} isBottomAction={false}>
						{
							sitemap === null
							?
							<>
								<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
									<NoDataContainer
										icon={icons.sad}
										heading={"No Sitemap Details found!"}
										description={"Update sitemap details by clicking on the below button."}
										hasSectionAction={false}
										sectionAction={() => alert("section add")}
										hasActions={true}
										hasPrimaryAction={true}
										primaryActionLable={"update"}
										primaryAction={() => setShowUpdateSitemapPopUp(true)}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
										secondaryAction={() => handleGetSitemapRequest("refresh")}
									/>
								</PlainContainer>
							</>
							:
							<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
								{
									sitemap?.page?.name != ""
									?
									<DetailsRow>
										<DetailsItem 
											hasIcon={false} 
											icon={``} 
											lable={"page"} 
											description={defaults.capitalize(sitemap?.page?.name)} 
										/>
									</DetailsRow>
									:
									<></>
								}
								{
									sitemap?.image != undefined
									?
									<DetailsRow>
										<DetailsItemWithPreview 
											lable={`Image`}
											preview={`${globals.API_BASE_URL}${sitemap?.image?.image_url?.full?.high_res}`}
											previewType={"image"}
											fileName={sitemap?.image?.name?.original}
											fileExtension={sitemap?.image?.extension?.original}
											fileSize={sitemap?.image?.size?.current}
										/>
									</DetailsRow>
									:
									<></>
								}
							</PlainContainer>
						}
					</Card>
				</PlainContainer>

				<PlainContainer styles={{marginTop: "25px"}}>
					<Card hasHeading={true} heading={"Products"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("product/add")} isBottomAction={false}>
						<PlainContainer type='full'>
							<Table>
								<TableHeading>
									<TableHeadingItem size={4} lable={"name"} />
									<TableHeadingItem size={1} lable={"created by"} />
									<TableHeadingItem size={0} lable={""} />
									<TableHeadingItem size={2} lable={"Actions"} />
								</TableHeading>

								<SortableContainer onSortEnd={handleSortEnd} useDragHandle>
									{products.map((item, index) => (
										<SortableItem key={`item-${index}`} index={index} value={item} />
									))}
								</SortableContainer>
							</Table>
						</PlainContainer>
					</Card>
				</PlainContainer>
			</PlainContainer>
			
			{
				showUpdateSitemapPopUp === true 
				? 
				<PopUp width="max" hasBottomPadding={true} heading="Update Sitemap" closePopUp={() => handleConcludeUpdateSitemapPopUp({ status: false })}>
					<UpdateUniformsPageSitemapPopUp user={user} sitemap={sitemap} concludeUpdateSitemapPopUp={(e) => handleConcludeUpdateSitemapPopUp(e) } />
				</PopUp>
			:
				<></>
			}
		</>
	);
};

export default UniformsPage;
