import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import {ContactUsPageServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateContactUsPageSitemapPopUp from '../../../components/dashboard/popups/Pages/ContactUsPage/UpdateContactUsPageSitemapPopUp';
import UpdateContactUsSectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/ContactUsPage/UpdateContactUsSectionHeadingsPagePopUp';
import UpdateContactLocationSectionHeadingPopUp from '../../../components/dashboard/popups/Pages/ContactUsPage/UpdateContactPageLocationSectionHeadingPopup';
import UpdateContactPageLocationPopUp from '../../../components/dashboard/popups/Pages/ContactUsPage/UpdateContactPageLocationPopUp';
import UpdateContactPageLocationsPopUp from '../../../components/dashboard/popups/Pages/ContactUsPage/UpdateContactPageLocationsPopUp';

const ContactUsPage = ({ user }) => {
    const navigate = useNavigate();

    const [seo, setSEO] = useState(null);

    const handleGetSEORequest = async () => {
        const getSEOReq = await ContactUsPageServices.getAuthSEO({
            token: user.access_token
        });

        if(getSEOReq.status === false){
            ToastAlert.notifyError(getSEOReq.message);
            setSEO(null);
        }
        else{
            setSEO(getSEOReq.data);
        }
    }

    useEffect(() => {
        handleGetSEORequest();
    }, []);

    


    const [sitemap, setSitemap] = useState(null);

    const handleGetSitemapRequest = async () => {
        const getSitemapReq = await ContactUsPageServices.getAuthSitemap({
            token: user.access_token
        });

        if(getSitemapReq.status === false){
            ToastAlert.notifyError(getSitemapReq.message);
            setSitemap(null);
        }
        else{
            setSitemap(getSitemapReq.data);
        }
    }

    useEffect(() => {
        handleGetSitemapRequest();
    }, []);

    const [showUpdateSitemapPopUp, setShowUpdateSitemapPopUp] = useState(false);

    const handleConcludeUpdateSitemapPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSitemapPopUp(false);
        }
        else{
            setShowUpdateSitemapPopUp(false);
            handleGetSitemapRequest();
        }
    }

    const [sectionHeading, setSectionHeading] = useState(null);

    const [banner, setBanner] = useState(null);
    
    const[location,setLocation]=useState(null)

    const handleGetPageContentReq = async () => {
        const getPageContentReq = await ContactUsPageServices.getAuthPageContent({
            token: user.access_token
        });

        if(getPageContentReq.status === false){
            ToastAlert.notifyError(getPageContentReq.message);
            setSectionHeading(null);
            setBanner(null)
        }
        else{
            console.log("contact location",getPageContentReq?.data?.location_section)
            setSectionHeading(getPageContentReq?.data?.headings);
            setBanner(getPageContentReq?.data?.ad_banner);
            setLocation(getPageContentReq?.data?.location_section)
        }
    }

    useEffect(() => {
        handleGetPageContentReq();
    }, []);

    const [showUpdateSectionHeadingsPopUp, setShowUpdateSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateSectionHeadingsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateSectionHeadingsPopUp(false);
            handleGetPageContentReq();
        }
    }

    const [showUpdateContactSectionHeadingsPopUp, setShowUpdateContactSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateContactSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateContactSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateContactSectionHeadingsPopUp(false);
            handleGetPageContentReq();
        }
    }

    const[ showAddContactLocationPopUp , setShowAddContactLocationPopUp]=useState(false);
    

    const handleConcludeAddContactHighlightPopUp= async (e)=>{
        if(e.status === false){
            setShowAddContactLocationPopUp(false)
        }
        else{
            setShowAddContactLocationPopUp(false)
            handleGetPageContentReq();
        }
    }

    const[ showUpdateContactLocationPopUp , setShowUpdateContactLocationPopUp]=useState(false);
    const[contactLocationToUpdate,setContactLocationToUpdate]=useState(null)

    const handleConcludeUpdateContactLocationPopUp= async (e)=>{
        if(e.status === false){
            setShowUpdateContactLocationPopUp(false)
        }
        else{
            setShowUpdateContactLocationPopUp(false)
            handleGetPageContentReq();
        }
    }

    const handleShowUpdateContactLocationPopUp = async (data) => {
        console.log(data);
        setContactLocationToUpdate(data);
        setShowUpdateContactLocationPopUp(true);
    }

    const handleDeleteContactLocation = async (action_id) => {
        console.log(action_id);
        const deleteContactLocationReq = await ContactUsPageServices.deleteContactLocation({
            id:action_id,
            token: user.access_token
        });

        if(deleteContactLocationReq.status === false){
            ToastAlert.notifyError(deleteContactLocationReq.message);
            setLocation([]);
        }
        else{
            handleGetPageContentReq()
        }
    }


    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableContactLocationItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
          
            <TableRowItem size={4} isCenter={false} hasPreview={false} lable={value?.name} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={3} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateContactLocationPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() =>handleDeleteContactLocation(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem> 
        </TableRow>
    ));
    
    const SortableContactLocationContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const handleSaveContactLocationSortedList = async (list) => {

        const sortHighlghtsReq = await ContactUsPageServices.sortContactLocation({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetPageContentReq();
        }
    }

    const handleContactLocationSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(location.locations, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveContactLocationSortedList(entityIdWithIndex);

        let data = {
            headings: location.section_headings,
            locations: [...arrayMove]
        }

        setLocation(data);
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Contact Us Page'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to Contact Us page in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("seo")} isBottomAction={false}>
                    {
                        seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => navigate("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSEORequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={seo?.image?.name?.original}
                                        fileExtension={seo?.image?.extension?.original}
                                        fileSize={seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sitemap"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSitemapPopUp(true)} isBottomAction={false}>
                        {
                            sitemap === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Sitemap Details found!"}
                                        description={"Update sitemap details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSitemapPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetSitemapRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"page"} 
                                            description={defaults.capitalize(sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={sitemap?.image?.name?.original}
                                            fileExtension={sitemap?.image?.extension?.original}
                                            fileSize={sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSectionHeadingsPopUp(true)} isBottomAction={false}>
                        {
                            sectionHeading === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Section Heading Details found!"}
                                        description={"Update Section Heading details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSectionHeadingsPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetPageContentReq("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                               {
                                    sectionHeading?.title != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Title"} 
                                            description={defaults.capitalize(sectionHeading?.heading)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                 {
                                    sectionHeading?.description != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Description"} 
                                            description={defaults.capitalize(sectionHeading?.description)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    sectionHeading?.icon != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${sectionHeading?.icon?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={sectionHeading?.icon?.name?.original}
                                            fileExtension={sectionHeading?.icon?.extension?.original}
                                            fileSize={sectionHeading?.icon?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
                <PlainContainer styles={{marginTop: "25px"}}>
                <Card hasHeading={true} heading={"Banner"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("banner")} isBottomAction={false}>
                    {
                        banner === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No Banner Details Details found!"}
                                    description={"Update Banner Details details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => navigate("banner")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetPageContentReq("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                banner?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(banner?.heading)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                banner?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(banner?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                banner?.action_heading != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"action heading"} 
                                        description={defaults.capitalize(banner?.action?.heading)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                banner?.phone_number!= ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"phone number"} 
                                        description={defaults.capitalize(banner?.action?.phone_number)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                             {
                                banner?.email_id!= ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"email id"} 
                                        description={defaults.capitalize(banner?.action?.email_id)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                banner?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${banner?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={banner?.image?.name?.original}
                                        fileExtension={banner?.image?.extension?.original}
                                        fileSize={banner?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>
                </PlainContainer>

                


                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Location Sections"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateContactSectionHeadingsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"heading"} 
                                            description={defaults.capitalize(location?.headings?.heading)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(location?.headings?.description)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                                
                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Locations"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddContactLocationPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <Table>
                                        <TableHeading>
                                            <TableHeadingItem size={4} lable={"name"} />
                                            <TableHeadingItem size={1} lable={"created by"} />
                                            <TableHeadingItem size={3} lable={"Actions"} />
                                        </TableHeading>

                                        <SortableContactLocationContainer onSortEnd={handleContactLocationSortEnd} useDragHandle>
                                            {location?.locations?.map((item, index) => (
                                                <SortableContactLocationItem key={`item-${index}`} index={index} value={item} />
                                            ))}
                                        </SortableContactLocationContainer>
                                    </Table>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        
                            </Card>
                        </PlainContainer>
                   </PlainContainer>
            
            {
                showUpdateSitemapPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Sitemap" closePopUp={() => handleConcludeUpdateSitemapPopUp({status: false})}>
                    <UpdateContactUsPageSitemapPopUp user={user} sitemap={sitemap} concludeUpdateSitemapPopUp={(e) => handleConcludeUpdateSitemapPopUp(e)} />
                </PopUp>
                :
                <></>
            }
                {
                showUpdateSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateSectionHeadingsPopUp({status: false})}>
                    <UpdateContactUsSectionHeadingsPopUp user={user} section_headings={sectionHeading} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateSectionHeadingsPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showUpdateContactSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateContactSectionHeadingPopUp({status: false})}>
                    <UpdateContactLocationSectionHeadingPopUp user={user} section_headings={location} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateContactSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showAddContactLocationPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Location" closePopUp={() => handleConcludeAddContactHighlightPopUp({status: false})}>
                    <UpdateContactPageLocationPopUp user={user} location_section={contactLocationToUpdate} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeAddContactHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showUpdateContactLocationPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Location" closePopUp={() => handleConcludeUpdateContactLocationPopUp({status: false})}>
                    <UpdateContactPageLocationsPopUp user={user} location_section={contactLocationToUpdate} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateContactLocationPopUp(e)} />
                </PopUp>
                :
                <></>
            }

        </>
    )
}

export default ContactUsPage