import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import FormActions from '../../../../components/form/FormActions';
import { constants, icons } from '../../../../constants';
import { globals, ToastAlert, validations } from '../../../../utils';
import FileUploadInput from '../../../../components/core/form/FileUploadInput';
import { ClientServices } from '../../../../services';

const AddClientPage = ({user}) => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [url, setURL] = useState("");
    const [hasURLIcon, setHasURLIcon] = useState(false);
    const [urlIcon, setURLIcon] = useState("");
    const [urlIconPosition, setURLIconPosition] = useState("right");
    const [urlIconType, setURLIconType] = useState("");
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Logo");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const nameChanged = async (e) => {
        const isNameValid = await validations.validateAllName(e);
        if(isNameValid.status){
            setName(e);
        }
        else if(e.length === 0){
            setName('');
        }
    }
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddClientSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        const isNameValid = await validations.validateAllName(name);

        if(isNameValid.status === false){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        if(isNameValid.status === false || selectedImages.length === 0){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveClientReq = await ClientServices.saveClient({
                name: name,
                url: url,
                image: selectedImages[0],
                token: user.access_token
            });

            if(saveClientReq.status === false){
                ToastAlert.notifyError(saveClientReq.message);
                setSubmitButtonLoading(false);
                setHasNameIcon(false);
                setNameIcon("");
                setNameIconType("");
            }
            else{
                ToastAlert.notifySuccess(saveClientReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Clients'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Clients.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/common/clients') }
                    >
                        View Clients
                    </Button>
                        
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new client"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddClientSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <SplitContainer>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Name"
                                            isMandatory={true}
                                            hasIcon={hasNameIcon}
                                            icon={nameIcon}
                                            iconPosition={nameIconPosition}
                                            iconType={nameIconType}
                                            hasMessage={hasNameMessage}
                                            message={nameMessage}
                                            messageType={nameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => nameChanged(e.target.value)}
                                                value={name}
                                            />
                                        </TextInputRT>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="URL"
                                            isMandatory={false}
                                            hasIcon={hasURLIcon}
                                            icon={urlIcon}
                                            iconPosition={urlIconPosition}
                                            iconType={urlIconType}
                                            hasMessage={hasURLMessage}
                                            message={urlMessage}
                                            messageType={urlMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter URL"}
                                                onChange={(e) => setURL(e.target.value)}
                                                value={url}
                                            />
                                        </TextInputRT>
                                    </SplitContainer>
                                    <FileUploadInput 
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddClientPage