import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { ProductServices } from '../../../../services';

const UpdateProductSubProductsPopUp = ({ user, product_id,sub_products_data, concludeUpdateSubProductsPopUp }) => {
    const [name, setName] = useState(sub_products_data != null ? sub_products_data.name : "");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [description, setDescription] = useState(sub_products_data != null ? sub_products_data.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    // const [selectedImages, setSelectedImages] = useState([]);
    // const [imageType, setImageType] = useState("image");
    // const [imageIsMultiple, setImageIsMultiple] = useState(false);
    // const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    // const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    // const [imageIsError, setImageIsError] = useState(false);
    // const [imageData, setImageData] = useState(null);

    // const [selectedImages1, setSelectedImages1] = useState([]);
    // const [image1Type, setImage1Type] = useState("image");
    // const [image1IsMultiple, setImage1IsMultiple] = useState(false);
    // const [image1MaxFileSelection, setImage1MaxFileSelection] = useState(1);
    // const [image1PopUpLable, setImage1PopUpLable] = useState("Image");
    // const [image1IsError, setImage1IsError] = useState(false);
    // const [image1Data, setImage1Data] = useState(null);

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    // const handleImageSelection = async (e) => {
    //     if(e.status === true){
    //         setSelectedImages([...e.files]);
    //         setImageIsError(false);
    //     }
    //     else{
    //         setSelectedImages([]);
    //     }
    // }

    // const handleImage1Selection = async (e) => {
    //     if(e.status === true){
    //         setSelectedImages1([...e.files]);
    //         setImage1IsError(false);
    //     }
    //     else{
    //         setSelectedImages1([]);
    //     }
    // }

    const handleAddActionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(name.trim() === ""){
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }
      if(description.trim() === ""){

            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        // if(selectedImages.length === 0){
        //     setImageIsError(true);
        // }
        // else{
        //     setImageIsError(false);
        // }

        // if(selectedImages1.length === 0){
        //     setImage1IsError(true);
        // }
        // else{
        //     setImage1IsError(false);
        // }

        // if(sub_products_data === null){

        //     if(selectedImages.length === 0){
        //         setImageIsError(true);
        //     }
        //     else{
        //         setImageIsError(false);
        //     }

        //     if(selectedImages1.length === 0){
        //         setImage1IsError(true);
        //     }
        //     else{
        //         setImage1IsError(false);
        //     }

        if(name.trim() === "" || description.trim() === "" ){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const updateSubProductsReq = await ProductServices.updateSubProduct({
                product_id: product_id,
                sub_product_id: sub_products_data.id,
                name:name,
                description:description,
                // image : selectedImages[0],
                // image1 : selectedImages1[0],
                token: user.access_token
            });

            if(updateSubProductsReq.status === false){
                ToastAlert.notifyError(updateSubProductsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateSubProductsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSubProductsPopUp({
                    status: true
                })
            }
        }
    // }
    // else{
    //     const updateSubProductsReq = await ProductServices.updateSubProduct({
    //         product_id: product_id,
    //         sub_product_id: sub_products_data.id,
    //         name:sub_products_data.name,
    //         description: sub_products_data.description,
    //         image: selectedImages.length > 0 ? selectedImages[0] : sub_products_data?.image?.id,
    //         image1: selectedImages1.length > 0 ? selectedImages1[0] : sub_products_data?.image_large?.id,
    //         token: user.access_token
    //     });

    //     if(updateSubProductsReq.status === false){
    //         ToastAlert.notifyError(updateSubProductsReq.message);
    //         setSubmitButtonLoading(false);
    //     }
    //     else{
    //         ToastAlert.notifySuccess(updateSubProductsReq.message);
    //         setSubmitButtonLoading(false);
    //         setSubmitButtonDisabled(true);
    //         setSubmitButtonBg('success');
    //         setSubmitButtonLable('Success');
    //         concludeUpdateSubProductsPopUp({
    //             status: true
    //         })
    //     }
    // } 
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleAddActionSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.productActionName.hasLable}
                        lable={inputs.productActionName.lable}
                        isMandatory={inputs.productActionName.is_mandatory}
                        hasMessage={hasNameMessage}
                        message={nameMessage}
                        messageType={nameMessageType}
                        isLimited={inputs.productActionName.is_limited}
                        limit={inputs.productActionName.max_characters}
                        value={name}
                    >
                        <input 
                            type={inputs.productActionName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.productActionName.placeholder}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </TextInputRT>
                    <TextAreaInput 
                        hasLable={inputs.whyChooseUsHighlightDescription.hasLable}
                        lable={inputs.whyChooseUsHighlightDescription.lable}
                        isMandatory={inputs.whyChooseUsHighlightDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    {/* <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${sub_products_data?.image?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FileUploadInput 
                        data={image1Data}
                        user={user} 
                        lable={image1PopUpLable} 
                        acceptedFileType={image1Type} 
                        isPreview={true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${sub_products_data?.image_large?.image_url?.full?.high_res}`}
                        isMultiple={image1IsMultiple} 
                        maxSelection={image1MaxFileSelection} 
                        isError={image1IsError}
                        action={(e) => handleImage1Selection(e)} 
                    /> */}

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSubProductsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateProductSubProductsPopUp