import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import TextAreaInput from '../../../../components/core/form/TextAreaInput';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import { defaults, ToastAlert, functions, globals } from '../../../../utils';
import {ContactUsPageServices } from '../../../../services';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import FileUploadInput from '../../../../components/core/form/FileUploadInput';
import FormActions from '../../../../components/form/FormActions';
import { inputs } from '../../../../constants';

const UpdateContactUsPageAddBannerPage = ({ user }) => {
    const navigate = useNavigate();

    const [banner, setBanner] = useState(null);

    const handleGetBannerRequest = async () => {
        const getBannerReq = await ContactUsPageServices.getAuthPageContent({
            token: user.access_token
        });

        if(getBannerReq.status === false){
            ToastAlert.notifyError(getBannerReq.message);
            setBanner(null);
        }
        else{
            console.log("add banner other page",getBannerReq?.data?.ad_banner);
            setBanner(getBannerReq?.data?.ad_banner);
            setTitle(getBannerReq?.data?.ad_banner?.heading);
            setDescription(getBannerReq?.data?.ad_banner?.description);
            setActionHeading(getBannerReq?.data?.ad_banner?.action?.heading);
            setPhoneNumber(getBannerReq?.data?.ad_banner?.action?.phone_number);
            setEmailID(getBannerReq?.data?.ad_banner?.action?.email_id);
            // setSelectedImages(getBannerReq?.data?.image?.image_url?.full?.high_res)
        }
    }

    useEffect(() => {
        handleGetBannerRequest();
       
    }, []);

    const [title, setTitle] = useState("");
    const [hasTitleIcon, setHasTitleIcon] = useState(false);
    const [titleIcon, setTitleIcon] = useState("");
    const [titleIconPosition, setTitleIconPosition] = useState("right");
    const [titleIconType, setTitleIconType] = useState("");
    const [hasTitleMessage, setHasTitleMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState("");
    const [titleMessageType, setTitleMessageType] = useState("");

    const [description, setDescription] = useState("");
    const [hasDescriptionIcon, setHasDescriptionIcon] = useState(false);
    const [descriptionIcon, setDescriptionIcon] = useState("");
    const [descriptionIconPosition, setDescriptionIconPosition] = useState("right");
    const [descriptionIconType, setDescriptionIconType] = useState("");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState("");
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [actionHeading, setActionHeading] = useState([]);
    const [hasActionHeadingMessage, setHasActionHeadingMessage] = useState(false);
    const [actionHeadingMessage, setActionHeadingMessage] = useState("");
    const [actionHeadingMessageType, setActionHeadingMessageType] = useState("");

    const [phoneNumber, setPhoneNumber] = useState([]);
    const [hasPhoneNumberMessage, setHasPhoneNumberMessage] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
    const [phoneNumberMessageType, setPhoneNumberMessageType] = useState("");

    const [emailID, setEmailID] = useState([]);
    const [hasEmailIDMessage, setHasEmailIDMessage] = useState(false);
    const [emailIDMessage, setEmailIDMessage] = useState("");
    const [emailIDMessageType, setEmailIDMessageType] = useState("");

    
    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const handleUpdateBannerSubmission = async (e) => {
        e.preventDefault();

    //     const saveAddBannerReq = await ContactUsPageServices.updateContactUsBanner({
    //         title: title,
    //         description: description,
    //         image: selectedImages[0],
    //         actionHeading:actionHeading,
    //         phoneNumber:phoneNumber,
    //         emailId:emailID,
    //         token: user.access_token
    //     });

    //     if(saveAddBannerReq.status === false){
    //         ToastAlert.notifyError(saveAddBannerReq.message);
    //         setSubmitButtonLoading(false);
    //     }
    //     else{
    //         ToastAlert.notifySuccess(saveAddBannerReq.message);
    //         setSubmitButtonLoading(false);
    //         setSubmitButtonDisabled(true);
    //         setSubmitButtonBg('success');
    //         setSubmitButtonHasIcon(true);
    //         setSubmitButtonLable('Success');
    //         navigate(-1);
    //     }
    // }

    if(banner === null){
        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        if(selectedImages.length === 0){
            setSubmitButtonLoading(false);
            return
        }
        else{
           
        const UpdateAddBannerReq = await ContactUsPageServices.updateContactUsBanner({
            title: title,
            description: description,
            image: selectedImages[0],
            actionHeading:actionHeading,
            phoneNumber:phoneNumber,
            emailId:emailID,
            token: user.access_token
        });

            if(UpdateAddBannerReq.status === false){
                ToastAlert.notifyError(UpdateAddBannerReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(UpdateAddBannerReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
               
            }
        }
    }
    else{
        const UpdateAddBannerReq = await ContactUsPageServices.updateContactUsBanner({
            title: title,
            description: description,
            image: selectedImages.length > 0 ? selectedImages[0] : banner.image.id,
            actionHeading:actionHeading,
            phoneNumber:phoneNumber,
            emailId:emailID,
            token: user.access_token
        });

        if(UpdateAddBannerReq.status === false){
            ToastAlert.notifyError(UpdateAddBannerReq.message);
            setSubmitButtonLoading(false);
        }
        else{
            ToastAlert.notifySuccess(UpdateAddBannerReq.message);
            setSubmitButtonLoading(false);
            setSubmitButtonDisabled(true);
            setSubmitButtonBg('success');
            setSubmitButtonLable('Success');
            navigate('/page/contact-us')
        }
    }
    
}

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Update Banner'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to Banner for the contact us page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"Update metadata to boost Banner"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleUpdateBannerSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Title"
                                        isMandatory={false}
                                        hasIcon={hasTitleIcon}
                                        icon={titleIcon}
                                        iconPosition={titleIconPosition}
                                        iconType={titleIconType}
                                        hasMessage={hasTitleMessage}
                                        message={titleMessage}
                                        messageType={titleIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Title"}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </TextInputRT>
                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasDescriptionMessage}
                                        message={descriptionMessage}
                                        messageType={descriptionMessageType}
                                    >
                                        <textarea 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"EnterDescription"}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={3}
                                            value={description}
                                        >
                                        </textarea>
                                    </TextAreaInput>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable={"action heading"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasActionHeadingMessage}
                                        message={actionHeadingMessage}
                                        messageType={actionHeadingMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter Action Heading"}
                                            onChange={(e) => setActionHeading(e.target.value)}
                                            rows={3}
                                            value={actionHeading}
                                        >
                                        </input>
                                    </TextInputRT>
                                   
                                    <TextInputRT 
                        hasLable={true}
                        lable={"phone number"}
                        isMandatory={false}
                        hasMessage={hasPhoneNumberMessage}
                        message={phoneNumberMessage}
                        messageType={phoneNumberMessageType}
                        isLimited={inputs.footerContactUsPhoneNumber.is_limited}
                        limit={inputs.footerContactUsPhoneNumber.max_characters}
                        value={phoneNumber}
                    >
                        <input 
                            type={inputs.footerContactUsPhoneNumber.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsPhoneNumber.placeholder}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={true}
                        lable={"email id"}
                        isMandatory={false}
                        hasMessage={hasEmailIDMessage}
                        message={emailIDMessage}
                        messageType={emailIDMessageType}
                        isLimited={inputs.footerContactUsEmailID.is_limited}
                        limit={inputs.footerContactUsEmailID.max_characters}
                        value={emailID}
                    >
                        <input 
                            type={inputs.footerContactUsEmailID.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsEmailID.placeholder}
                            onChange={(e) => setEmailID(e.target.value)}
                            value={emailID}
                        />
                    </TextInputRT>
                                    
                                    <FileUploadInput 
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isPreview={banner?.image === undefined ? false : true}
                                        previewType={"image"}
                                        preview={`${globals.API_BASE_URL}${banner?.image?.image_url?.full?.high_res}`}
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default UpdateContactUsPageAddBannerPage

