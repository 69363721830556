import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import {ContactUsPageServices } from '../../../../../services';

const UpdateContactLocationSectionHeadingPopUp = ({ user, section_headings, concludeUpdateSectionHeadingsPopUp }) => {
    
    console.log("section headings contact",section_headings)

    const [heading, setHeading] = useState(section_headings != null ? section_headings?.headings?.heading : "");
    const [hasHeadingMessage, setHasHeadingMessage] = useState(false);
    const [headingMessage, setHeadingMessage] = useState("");
    const [headingMessageType, setHeadingMessageType] = useState("");

    const [description, setDescription] = useState(section_headings != null ? section_headings?.headings?.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

       

        if(heading.trim() === ""){
            setHasHeadingMessage(true);
            setHeadingMessage("Invalid Value");
            setHeadingMessageType("error");
        }
        else{
            setHasHeadingMessage(false);
            setHeadingMessage("");
            setHeadingMessageType("");
        }

        if( heading.trim() === "" || description.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveContactSectionHeadingsReq = await ContactUsPageServices.updateContactSectionHeadings({
                heading: heading,
                description: description,
                token: user.access_token
            });

            if(saveContactSectionHeadingsReq.status === false){
                ToastAlert.notifyError(saveContactSectionHeadingsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveContactSectionHeadingsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSectionHeadingsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingsSubmission(e)} noValidate>
                <div className="cmsForm">
                   
                    <TextInputRT 
                        hasLable={inputs.whyChooseUsHeading.hasLable}
                        lable={inputs.whyChooseUsHeading.lable}
                        isMandatory={inputs.whyChooseUsHeading.is_mandatory}
                        hasMessage={hasHeadingMessage}
                        message={headingMessage}
                        messageType={headingMessageType}
                        isLimited={inputs.whyChooseUsHeading.is_limited}
                        limit={inputs.whyChooseUsHeading.max_characters}
                        value={heading}
                    >
                        <input 
                            type={inputs.whyChooseUsHeading.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.whyChooseUsHeading.placeholder}
                            onChange={(e) => setHeading(e.target.value)}
                            value={heading}
                        />
                    </TextInputRT>

                    <TextAreaInput 
                        hasLable={inputs.whyChooseUsDescription.hasLable}
                        lable={inputs.whyChooseUsDescription.lable}
                        isMandatory={inputs.whyChooseUsDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateContactLocationSectionHeadingPopUp