import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { functions, globals, ToastAlert } from '../../../utils';
import { ClientServices } from '../../../services';
import UpdateClientPage from '../../../components/dashboard/popups/Pages/ClientPage/UpdateClientPopup';
import UpdateClientPagePopup from '../../../components/dashboard/popups/Pages/ClientPage/UpdateClientPopup';
import PopUp from '../../../components/core/popup';


const ClientsListPage = ({ user }) => {
    const navigate = useNavigate();

    const [clients, setClients] = useState([]);

    const handleGetClientsRequest = async () => {
        const getClientsReq = await ClientServices.getAllClients({
            token: user.access_token
        });

        if(getClientsReq.status === false){
            ToastAlert.notifyError(getClientsReq.message);
            setClients([]);
        }
        else{
            if(getClientsReq.data.length > 0){
                setClients(getClientsReq.data);
            }
            else{
                setClients([]);
            }
        }
    }

    useEffect(() => {
        handleGetClientsRequest();
    }, []);

    const handleSaveSortedList = async (list) => {

        const sortClientsReq = await ClientServices.sortClients({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortClientsReq.status === false){
            ToastAlert.notifyError(sortClientsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortClientsReq.message);
            handleGetClientsRequest();
        }
    }

    const [showUpdateClientPopUp, setShowUpdateClientPopUp] = useState(false);
    const [clientToUpdate, setClientToUpdate] = useState(null);
    
    const handleEditClient = async (data) => {
        setShowUpdateClientPopUp(true)
        setClientToUpdate(data)
    }
    
    const handleConcludeClientPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateClientPopUp(false);
        }
        else{
            setShowUpdateClientPopUp(false);
            handleGetClientsRequest();
        }
    }

    const handleDeleteClient = async (action_id) => {
        console.log(action_id);
        const deleteClientsReq = await ClientServices.deleteClient({
            id:action_id,
            token: user.access_token
        });

        if(deleteClientsReq.status === false){
            ToastAlert.notifyError(deleteClientsReq.message);
            setClients([]);
        }
        else{
            handleGetClientsRequest()
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(clients, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setClients([...arrayMove]);
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

const SortableItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={3} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.image.image_url.thumbnail.low_res}`} lable={value.name} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={0} isCenter={true} hasChildren={true}>
            <TableRowIcons>
                <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
            </TableRowIcons>
        </TableRowItem>
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleEditClient(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteClient(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Clients'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Clients.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={3} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                                    {clients.map((item, index) => (
                                        <SortableItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableContainer>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
            {
                showUpdateClientPopUp === true
                ?
                <PopUp width="max" heading="Update Client Details" closePopUp={() => handleConcludeClientPopUp({status: false})}>
                    <UpdateClientPagePopup user={user} data={clientToUpdate} concludeUpdateClientPopUp={(e) => handleConcludeClientPopUp(e)} />
                </PopUp>
                :
                <></>
             }-
        </>
    )
}

export default ClientsListPage
    