import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { ContactUsPageServices, PageServices } from '../../../../../services';

const UpdateContactUsSectionHeadingsPopUp = ({ user, section_headings, concludeUpdateSectionHeadingsPopUp }) => {
    
    const [heading, setHeading] = useState(section_headings != null ? section_headings.heading : "");
    const [hasHeadingMessage, setHasHeadingMessage] = useState(false);
    const [headingMessage, setHeadingMessage] = useState("");
    const [headingMessageType, setHeadingMessageType] = useState("");

    const [description, setDescription] = useState(section_headings != null ? section_headings.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const [pages, setPages] = useState([]);


    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([])
        }
    }

    const handleGetPagesRequest = async () => {
        const getPagesReq = await PageServices.getAllActiveAuthPages({
            token: user.access_token,
        });

        if(getPagesReq.status === false){
            ToastAlert.notifyError(getPagesReq.message);
            setPages([]);
        }
        else{
            if(getPagesReq.data.length > 0){
                setPages(getPagesReq.data);
            }
            else{
                setPages([]);
            }
        }
    }

    
    useEffect(() => {
        handleGetPagesRequest();
    }, []);


    const handleUpdateSectionHeadingsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);


        if(heading.trim() === ""){
            setHasHeadingMessage(true);
            setHeadingMessage("Invalid Value");
            setHeadingMessageType("error");
        }
        else{
            setHasHeadingMessage(false);
            setHeadingMessage("");
            setHeadingMessageType("");
        }
        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Value");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }
        
        if(section_headings === null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }

            if(heading.trim() === "" || description.trim() === "" || selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveSectionHeadingReq = await ContactUsPageServices.saveSectionHeadings({
                    heading: heading,
                    description: description,
                    icon: selectedImages[0],
                    token: user.access_token
                });
    
                if(saveSectionHeadingReq.status === false){
                    ToastAlert.notifyError(saveSectionHeadingReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveSectionHeadingReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSectionHeadingsPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            const updateSectionHeadingReq = await ContactUsPageServices.saveSectionHeadings({
                heading: heading.trim(),
                description: description.trim(),
                icon: selectedImages.length > 0 ? selectedImages[0] : section_headings.icon.id,
                token: user.access_token
            });

            if(updateSectionHeadingReq.status === false){
                ToastAlert.notifyError(updateSectionHeadingReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateSectionHeadingReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSectionHeadingsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingsSubmission(e)} noValidate>
                <div className="cmsForm">
                    
                    <TextInputRT 
                        hasLable={inputs.whyChooseUsHeading.hasLable}
                        lable={inputs.whyChooseUsHeading.lable}
                        isMandatory={inputs.whyChooseUsHeading.is_mandatory}
                        hasMessage={hasHeadingMessage}
                        message={headingMessage}
                        messageType={headingMessageType}
                        isLimited={inputs.whyChooseUsHeading.is_limited}
                        limit={inputs.whyChooseUsHeading.max_characters}
                        value={heading}
                    >
                        <input 
                            type={inputs.whyChooseUsHeading.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.whyChooseUsHeading.placeholder}
                            onChange={(e) => setHeading(e.target.value)}
                            value={heading}
                        />
                    </TextInputRT>

                    <TextAreaInput 
                        hasLable={inputs.whyChooseUsDescription.hasLable}
                        lable={inputs.whyChooseUsDescription.lable}
                        isMandatory={inputs.whyChooseUsDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >

                        </textarea>
                    </TextAreaInput>
                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={section_headings === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${section_headings?.icon?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateContactUsSectionHeadingsPopUp