import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { TestimonialServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateTestimonialSectionHeadingPopUp from '../../../components/dashboard/popups/Testimonial/UpdateTestimonialSectionHeadingPopUp';
import AddTestimonialReviewPopUp from '../../../components/dashboard/popups/Testimonial/AddTestimonialReviewPopUp';
import UpdateTestimonialReviewPopUp from '../../../components/dashboard/popups/Testimonial/UpdateTestimonialReviewPopUp';

const TestimonialPage = ({ user }) => {
    const navigate = useNavigate();

    const [sectionHeadings, setSectionHeadings] = useState(null);

    const [showUpdateSectionHeadingsPopUp, setShowUpdateSectionHeadingsPopUp] = useState(false);

    const handleGetSectionHeadingsRequest = async () => {
        const getSectionHeadingsReq = await TestimonialServices.getAuthSectionHeadings({
            token: user.access_token
        });

        if(getSectionHeadingsReq.status === false){
            ToastAlert.notifyError(getSectionHeadingsReq.message);
            setSectionHeadings(null);
        }
        else{
            setSectionHeadings(getSectionHeadingsReq.data);
        }
    }

    useEffect(() => {
        handleGetSectionHeadingsRequest();
    }, []);

    const handleConcludeUpdateSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateSectionHeadingsPopUp(false);
            handleGetSectionHeadingsRequest();
        }
    }

    const [reviews, setReviews] = useState([]);

    const handleGetReviewsRequest = async () => {
        const getReviewsReq = await TestimonialServices.getAuthReviews({
            token: user.access_token
        });

        if(getReviewsReq.status === false){
            ToastAlert.notifyError(getReviewsReq.message);
            setReviews(null);
        }
        else{
            setReviews(getReviewsReq.data);
        }
    }

    useEffect(() => {
        handleGetReviewsRequest();
    }, []);

    const [showAddReviewPopUp, setShowAddReviewPopUp] = useState(false);

    const handleConcludeAddReviewPopUp = async (e) => {
        if(e.status === false){
            setShowAddReviewPopUp(false);
        }
        else{
            setShowAddReviewPopUp(false);
            handleGetReviewsRequest();
        }
    }

    const handleSaveSortedList = async (list) => {

        const sortHighlghtsReq = await TestimonialServices.sortReviews({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetReviewsRequest();
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(reviews, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setReviews([...arrayMove]);
    }


    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.image.image_url.thumbnail.low_res}`} lable={value.client_name} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateReviewPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteTestimonialReview(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const [showUpdateReviewPopUp, setShowUpdateReviewPopUp] = useState(false);
    const [reviewToUpdate, setReviewToUpdate] = useState(null);

    const handleShowUpdateReviewPopUp = async (data) => {
        setReviewToUpdate(data);
        setShowUpdateReviewPopUp(true);
    }

    const handleConcludeUpdateReviewPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateReviewPopUp(false);
        }
        else{
            setShowUpdateReviewPopUp(false);
            handleGetReviewsRequest();
        }
    }

    const handleDeleteTestimonialReview = async (action_id) => {
        console.log(action_id);
        const TestimonialReview = await TestimonialServices.deleteTestimonialReview({
            id:action_id,
            token: user.access_token
        });

        if(TestimonialReview.status === false){
            ToastAlert.notifyError(TestimonialReview.message);
            setReviews([]);
        }
        else{
            handleGetReviewsRequest()
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Testimonials'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to testimonials in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSectionHeadingsPopUp(true)} isBottomAction={false}>
                    
                    {
                        sectionHeadings === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No section headings found!"}
                                    description={"Update section headings by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => setShowUpdateSectionHeadingsPopUp(true)}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSectionHeadingsRequest()}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section tagline"} 
                                    description={sectionHeadings?.tagline} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section heading"} 
                                    description={sectionHeadings?.heading} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section description"} 
                                    description={sectionHeadings?.description} 
                                />
                            </DetailsRow>
                        </PlainContainer>
                    }
                </Card> 

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Reviews"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddReviewPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={4} lable={"client name"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                                    {reviews.map((item, index) => (
                                        <SortableItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

            </PlainContainer>


            {
                showUpdateSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateSectionHeadingPopUp({status: false})}>
                    <UpdateTestimonialSectionHeadingPopUp user={user} section_headings={sectionHeadings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddReviewPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Review" closePopUp={() => handleConcludeAddReviewPopUp({status: false})}>
                    <AddTestimonialReviewPopUp user={user} concludeAddReviewPopUp={(e) => handleConcludeAddReviewPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateReviewPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Review" closePopUp={() => handleConcludeUpdateReviewPopUp({status: false})}>
                    <UpdateTestimonialReviewPopUp user={user} reviewData={reviewToUpdate} concludeUpdateReviewPopUp={(e) => handleConcludeUpdateReviewPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        
        </>
    )
}

export default TestimonialPage