import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import {PageServices, UniformsPageServices } from '../../../../../services';
import RadioButtonGroup from '../../../../core/form/RadioButtonGroup';
import RadioButtonInput from '../../../../core/form/RadioButtonInput';
import SelectInput from '../../../../core/form/SelectInput';
import DefaultsServices from '../../../../../services/Defaults';

const UpdateDefaultsPageBrandPopUp = ({ user, brand, concludeUpdateBrandPopUp }) => {

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Icon");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

   

    const handleUpdateBrandSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(brand === null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }

            if(selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const updateSitemapReq = await DefaultsServices.updateBrand({
                    image: selectedImages[0],
                    token: user.access_token
                });
    
                if(updateSitemapReq.status === false){
                    ToastAlert.notifyError(updateSitemapReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(updateSitemapReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateBrandPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            const updateSitemapReq = await DefaultsServices.updateBrand({
                image: selectedImages.length > 0 ? selectedImages[0] : brand.square.id,
                token: user.access_token
            });

            if(updateSitemapReq.status === false){
                ToastAlert.notifyError(updateSitemapReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateSitemapReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateBrandPopUp({
                    status: true
                })
            }
        }
        
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateBrandSubmission(e)} noValidate>
                <div className="cmsForm">

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={brand === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${brand?.square?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateBrandPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateDefaultsPageBrandPopUp