import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import {PageServices, UniformsPageServices } from '../../../../../services';
import RadioButtonGroup from '../../../../core/form/RadioButtonGroup';
import RadioButtonInput from '../../../../core/form/RadioButtonInput';
import SelectInput from '../../../../core/form/SelectInput';

const UpdateUniformsPageSitemapPopUp = ({ user, sitemap, concludeUpdateSitemapPopUp }) => {

    const [pages, setPages] = useState([]);

    const [gotoPage, setGotoPage] = useState(sitemap === null ? 0 : sitemap.page.id);
    const [hasGotoPageMessage, setHasGotoPageMessage] = useState(false);
    const [gotoPageMessage, setGotoPageMessage] = useState("");
    const [gotoPageMessageType, setGotoPageMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Icon");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleGetPagesRequest = async () => {
        const getPagesReq = await PageServices.getAllActiveAuthPages({
            token: user.access_token,
        });

        if(getPagesReq.status === false){
            ToastAlert.notifyError(getPagesReq.message);
            setPages([]);
        }
        else{
            if(getPagesReq.data.length > 0){
                setPages(getPagesReq.data);
            }
            else{
                setPages([]);
            }
        }
    }

    useEffect(() => {
        handleGetPagesRequest();
    }, []);

    const handleUpdateSitemapSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(gotoPage == 0){
            setHasGotoPageMessage(true);
            setGotoPageMessage("Page selection is required");
            setGotoPageMessageType("error");
        }
        else{
            setHasGotoPageMessage(false);
            setGotoPageMessage("");
            setGotoPageMessageType("");
        }

        if(sitemap === null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }

            if(gotoPage == 0 || selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const updateSitemapReq = await UniformsPageServices.updateSitemap({
                    page: gotoPage,
                    image: selectedImages[0],
                    token: user.access_token
                });
    
                if(updateSitemapReq.status === false){
                    ToastAlert.notifyError(updateSitemapReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(updateSitemapReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSitemapPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            const updateSitemapReq = await UniformsPageServices.updateSitemap({
                page: gotoPage,
                image: selectedImages.length > 0 ? selectedImages[0] : sitemap.image.id,
                token: user.access_token
            });

            if(updateSitemapReq.status === false){
                ToastAlert.notifyError(updateSitemapReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateSitemapReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSitemapPopUp({
                    status: true
                })
            }
        }
        
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSitemapSubmission(e)} noValidate>
                <div className="cmsForm">
                    <SelectInput 
                        hasLable={inputs.primaryNavigationItemGotoPage.hasLable}
                        lable={inputs.primaryNavigationItemGotoPage.lable}
                        isMandatory={inputs.primaryNavigationItemGotoPage.is_mandatory}
                        hasMessage={hasGotoPageMessage}
                        message={gotoPageMessage}
                        messageType={gotoPageMessageType}
                    >
                        <select className="cmsFormStepInputSelect" value={gotoPage} onChange={(e) => setGotoPage(e.target.value)}>
                            <option value={inputs.primaryNavigationItemGotoPage.value} disabled={true}>{inputs.primaryNavigationItemGotoPage.placeholder}</option>
                            {
                                pages.map((page, i) => {
                                    return (
                                        <option key={i} value={page.id}>{page?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </SelectInput>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={sitemap === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${sitemap?.image?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSitemapPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateUniformsPageSitemapPopUp