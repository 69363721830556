import React from 'react'
import { useLocation } from 'react-router-dom';
import NavbarHeader from './NavbarHeader'
import NavBarUserDetails from './UserDetails'
import NavbarContent from './NavbarContent'
import NavbarSection from './NavSection'
import NavbarActionItem from './NavItem'
import { icons } from '../../../../../constants'
import { functions } from '../../../../../utils';

const routeList = [
    {
        basic: {
            heading: "dashboards",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"home", 
                goTo:"/dashboard/home", 
                icon: icons.home, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"defaults", 
                goTo:"/dashboard/defaults", 
                icon: icons.settingsGear, 
                tagline: "", 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"leads", 
                goTo:"/dashboard/leads", 
                icon: icons.mapRealistic, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [
                    // { 
                    //     name:"Request for Quote", 
                    //     goTo:"/dashboard/leads/request-for-quote", 
                    //     tagline: '', 
                    //     badgeData: {
                    //         hasBadge: false, 
                    //         badgeType: "default", 
                    //         badgeBg: "default", 
                    //         lable: ""
                    //     }
                    // },
                    { 
                        name:"Requested  Samples", 
                        goTo:"/dashboard/leads/sample-request", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    },
                    { 
                        name:"Requested Callbacks", 
                        goTo:"/dashboard/leads/request-for-callback", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    }
                ] 
            }
        ]
    },
    {
        basic: {
            heading: "general",
            description: "All you need at your fingertips"
        },
        routes: [
            {
                name:"users", 
                goTo:"/general/users", 
                icon: icons.user, 
                tagline: 'Manage users of CMS Owner', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [
                    { 
                        name:"Manage Admins", 
                        goTo:"/general/users/admins", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    },
                    // { 
                    //     name:"Manage Creators", 
                    //     goTo:"/general/users/creators", 
                    //     tagline: '', 
                    //     badgeData: {
                    //         hasBadge: false, 
                    //         badgeType: "default", 
                    //         badgeBg: "default", 
                    //         lable: ""
                    //     }
                    // },
                    // { 
                    //     name:"Manage Moderators", 
                    //     goTo:"/general/users/moderators", 
                    //     tagline: '', 
                    //     badgeData: {
                    //         hasBadge: false, 
                    //         badgeType: "default", 
                    //         badgeBg: "default", 
                    //         lable: ""
                    //     }
                    // },
                    // { 
                    //     name:"Manage Approvers", 
                    //     goTo:"/general/users/approvers", 
                    //     tagline: '', 
                    //     badgeData: {
                    //         hasBadge: false, 
                    //         badgeType: "default", 
                    //         badgeBg: "default", 
                    //         lable: ""
                    //     }
                    // },
                    // { 
                    //     name:"Reset Password Requests", 
                    //     goTo:"/general/users/reset-password-requests", 
                    //     tagline: '', 
                    //     badgeData: {
                    //         hasBadge: false, 
                    //         badgeType: "default", 
                    //         badgeBg: "default", 
                    //         lable: ""
                    //     }
                    // }
                ] 
            },
            {
                name:"file manager", 
                goTo:"/general/file-manager", 
                icon: icons.imageVideo, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            {
                name:"pages", 
                goTo:"/general/pages", 
                icon: icons.copy, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            }
        ]
    },
    {
        basic: {
            heading: "masterdata",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"media types", 
                goTo:"/masterdata/media-types", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"avatars", 
                goTo:"/masterdata/avatars", 
                icon: icons.userProfessional, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"locations", 
                goTo:"/masterdata/locations", 
                icon: icons.mapRealistic, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [
                    { 
                        name:"Manage States", 
                        goTo:"/masterdata/locations/states", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    },
                    { 
                        name:"Manage Districts", 
                        goTo:"/masterdata/locations/districts", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    },
                    { 
                        name:"Manage Cities", 
                        goTo:"/masterdata/locations/cities", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    }
                ] 
            }
        ]
    },
    {
        basic: {
            heading: "common",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"header", 
                goTo:"/common/header", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"why choose us?", 
                goTo:"/common/why-choose-us", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"clients", 
                goTo:"/common/clients", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"testimonials", 
                goTo:"/common/testimonials", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"request a quote", 
                goTo:"/common/request-a-quote", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"footer", 
                goTo:"/common/footer", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            }
        ]
    },
    {
        basic: {
            heading: "pages",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"home", 
                goTo:"/page/home", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"about us", 
                goTo:"/page/about-us", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"uniforms", 
                goTo:"/page/uniforms", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"corporate gifting", 
                goTo:"/page/corporate-gifting", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"clientele", 
                goTo:"/page/clientele", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"blog", 
                goTo:"/page/blog", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"contact us", 
                goTo:"/page/contact-us", 
                icon: icons.film, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            }
        ]
    }
]

const SuperAdminLayoutNavBar = ({ user }) => {
    const location = useLocation();

    return (
        <div className="dashboardNavBar">
            <NavbarHeader />
            <NavBarUserDetails
                avatar={user.avatar === "" ? '/images/default-avatar.jpg' : user?.avatar}
                name={user.name}
                emailID={user.email_id}
            />
            <NavbarContent>
                {
                    routeList.map((section, i) => {
                        return (
                            <NavbarSection heading={section.basic.heading} description={section.basic.description} key={i}>
                                {
                                    section.routes.map((route, i) => {
                                        return(
                                            <NavbarActionItem 
                                                key={i}
                                                icon={route.icon}
                                                lable={route.name}
                                                tagline={route.tagline}
                                                isBadge={route.badgeData.hasBadge}
                                                badgeType={route.badgeData.badgeType}
                                                badgeBg={route.badgeData.badgeBg}
                                                badgeContent={route.badgeData.lable}
                                                goTo={route.goTo}
                                                isActive={location.pathname.includes(route.goTo)} 
                                                hasSubRoutes={route.subRoutes.length != 0 ? true : false}
                                                subRoutes={route.subRoutes}
                                            />
                                        )
                                    })
                                }
                            </NavbarSection>
                        )
                    })
                }
            </NavbarContent>
        </div>
    )
}

export default SuperAdminLayoutNavBar