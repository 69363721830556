import React, { useEffect, useState, forwardRef, useRef, useMemo  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import TextAreaInput from '../../../../components/core/form/TextAreaInput';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import { defaults, ToastAlert, functions, globals, validations } from '../../../../utils';
import { ProductServices } from '../../../../services';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import FileUploadInput from '../../../../components/core/form/FileUploadInput';
import FormActions from '../../../../components/form/FormActions';
import { useParams } from 'react-router-dom';
import DefaultsServices from '../../../../services/Defaults';


const UpdateSocialPage = ({ user }) => {

    const navigate = useNavigate();
    const params= useParams()
   

    const [platform, setPlatform] = useState("");
    const [hasPlatformIcon, setHasPlatformIcon] = useState(false);
    const [platformIcon, setPlatformIcon] = useState("");
    const [platformIconPosition, setPlatformIconPosition] = useState("right");
    const [platformIconType, setPlatformIconType] = useState("");
    const [hasPlatformMessage, setHasPlatformMessage] = useState(false);
    const [platformMessage, setPlatformMessage] = useState("");
    const [platformMessageType, setPlatformMessageType] = useState("");

    const [action, setAction] = useState("");
    const [hasActionIcon, setHasActionIcon] = useState(false);
    const [actionIcon, setActionIcon] = useState("");
    const [actionIconPosition, setActionIconPosition] = useState("right");
    const [actionIconType, setActionIconType] = useState("");
    const [hasActionMessage, setHasActionMessage] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [actionMessageType, setActionMessageType] = useState("");


    const [icon, setIcon] = useState("");
    const [hasIconIcon, setHasIconIcon] = useState(false);
    const [iconIcon, setIconIcon] = useState("");
    const [iconIconPosition, setIconIconPosition] = useState("right");
    const [iconIconType, setIconIconType] = useState("");
    const [hasIconMessage, setHasIconMessage] = useState(false);
    const [iconMessage, setIconMessage] = useState("");
    const [iconMessageType, setIconMessageType] = useState("");

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");
    
   

    const [social, setSocial] = useState(null);

    const handleGetSocialRequest = async () => {
        const getSocialReq = await DefaultsServices.getThisAuthSocial({
            id: params.id,
            token: user.access_token
        });

        if(getSocialReq.status === false){
            ToastAlert.notifyError(getSocialReq.message);
            setSocial(null);
        }
        else{
            console.log("social edit page",getSocialReq?.data);
            setSocial(getSocialReq?.data);
            setIcon(getSocialReq?.data?.icon)
            setPlatform(getSocialReq?.data?.platform_name);
            setAction(getSocialReq?.data?.action);
        }
    }

    useEffect(() => {
        handleGetSocialRequest();
    }, []);


    
    const handleUpdateSocialSubmission = async (e) => {
            e.preventDefault();
            setSubmitButtonLoading(true);

            if(platform === ""){
                setHasPlatformIcon(true);
                setPlatformIcon(icons.wrongCircle);
                setPlatformIconType("error");
                setHasPlatformMessage(true);
                setPlatformMessage("Invalid URL");
                setPlatformMessageType("error");
            }
            else{
                setHasPlatformIcon(true);
                setPlatformIcon(icons.tickCircle);
                setPlatformIconType("success");
                setHasPlatformMessage(false);
                setPlatformMessage("");
                setPlatformMessageType("");
            }
    
            if(action === ""){
                setHasActionIcon(true);
                setActionIcon(icons.wrongCircle);
                setActionIconType("error");
                setHasActionMessage(true);
                setActionMessage("Invalid Title");
                setActionMessageType("error");
            }
            else{
                setHasActionIcon(true);
                setActionIcon(icons.tickCircle);
                setActionIconType("success");
                setHasActionMessage(false);
                setActionMessage("");
                setActionMessageType("");
            }

            if(icon === ""){
                setHasIconIcon(true);
                setIconIcon(icons.wrongCircle);
                setIconIconType("error");
                setHasIconMessage(true);
                setIconMessage("Invalid URL");
                setIconMessageType("error");
            }
            else{
                setHasIconIcon(true);
                setIconIcon(icons.tickCircle);
                setIconIconType("success");
                setHasIconMessage(false);
                setHasIconMessage("");
                setIconMessageType("");
            }

            if(platform === false || action === ""){
                setSubmitButtonLoading(false);
                return
            }
                else{
                    const updateSocialReq = await DefaultsServices.updateSocial({
                        id:social.id,
                        action: action,
                        platform: platform,
                        image: icon,
                        token: user.access_token
                    });
                    if(updateSocialReq.status === false){
                        ToastAlert.notifyError(updateSocialReq.message);
                        setSubmitButtonLoading(false);
                    }
                    else{
                        ToastAlert.notifySuccess(updateSocialReq.message);
                        setSubmitButtonLoading(false);
                        setSubmitButtonDisabled(true);
                        setSubmitButtonBg('success');
                        setSubmitButtonHasIcon(true);
                        setSubmitButtonLable('Success');
                        navigate(-1);
                    }
                }   
             }
                                
                                    

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Update Social Media Platform'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Update Social Media Platform to display in the website'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"Update Social"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleUpdateSocialSubmission(e)} noValidate>
                                <div className="cmsForm">
                                <TextInputRT 
                                        hasLable={true}
                                        lable="Platform"
                                        isMandatory={false}
                                        hasIcon={hasPlatformIcon}
                                        icon={platformIcon}
                                        iconPosition={platformIconPosition}
                                        iconType={platformIconType}
                                        hasMessage={hasPlatformMessage}
                                        message={platformMessage}
                                        messageType={platformMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Platform"}
                                            onChange={(e) => setPlatform(e.target.value)}
                                            value={platform}
                                        />
                                    </TextInputRT>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="action"
                                        isMandatory={false}
                                        hasIcon={hasActionIcon}
                                        icon={actionIcon}
                                        iconPosition={actionIconPosition}
                                        iconType={actionIconType}
                                        hasMessage={hasActionMessage}
                                        message={actionMessage}
                                        messageType={actionIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter action"}
                                            onChange={(e) => setAction(e.target.value)}
                                            value={action}
                                        />
                                    </TextInputRT>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="icon"
                                        isMandatory={false}
                                        hasIcon={hasIconIcon}
                                        icon={iconIcon}
                                        iconPosition={iconIconPosition}
                                        iconType={iconIconType}
                                        hasMessage={hasIconMessage}
                                        message={iconMessage}
                                        messageType={iconIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter icon"}
                                            onChange={(e) => setIcon(e.target.value)}
                                            value={icon}
                                        />
                                    </TextInputRT>
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default UpdateSocialPage
