import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import DetailsRow from '../../../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../../../components/core/containers/DetailsItem';
import DetailsItemWithPreview from '../../../../../components/core/containers/DetailsItemWithPreview';
import Table from '../../../../../components/core/table/containers/Table';
import TableHeading from '../../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../../../utils';
import { ArticlePageServices, ProductServices } from '../../../../../services';
import PopUp from '../../../../../components/core/popup';
import NoDataContainer from '../../../../../components/core/containers/NoDataContainer';
import DetailsItemInnerText from '../../../../../components/core/containers/DetailsItemInnerText';
import AddArticleActionPopUp from '../../../../../components/dashboard/popups/Article/AddArticleActionPopup';
import UpdateArticleActionPopUp from '../../../../../components/dashboard/popups/Article/UpdateArticleActionPopup';
import UpdateBlogArticlePageSitemapPopUp from '../../../../../components/dashboard/popups/Pages/BlogPage/UpdateBlogArticlePageSitemapPopUp';

const ArticleDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();
   

    const [articles, setArticles] = useState(null);

    const handleGetArticleRequest = async () => {
        const getArticleReq = await ArticlePageServices.getThisAuthArticle({
            article_id: params.id,
            token: user.access_token
        });

        if(getArticleReq.status === false){
            ToastAlert.notifyError(getArticleReq.message);
            setArticles(null);
        }
        else{
            console.log("view page details  article page",getArticleReq.data);
            setArticles(getArticleReq.data);
        }
    }

    useEffect(() => {
        handleGetArticleRequest();
    }, []);

    const handleSaveSortedActionList = async (list) => {

        const sortActionsReq = await ArticlePageServices.sortActions({
            items: JSON.stringify(list),
            blog_id: articles.id,
            token: user.access_token
        });

        if(sortActionsReq.status === false){
            ToastAlert.notifyError(sortActionsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortActionsReq.message);
            handleGetArticleRequest();
        }
    }

    const handleSortActionEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(articles.article.actions, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedActionList(entityIdWithIndex);

        let data = {
            id: articles.id,
            blog_id: articles.blog_id,
            seo: articles.seo,
            sitemap: articles.sitemap,
            product: {
                title: articles.article.title,
                short_description: articles.article.short_description,
                description: articles.article.description,
                images: articles.article.images,
                actions: [...arrayMove],
            },
            created_by: articles.created_by,
            is_active: articles.is_active,
            is_archived: articles.is_archived,
            createdAt: articles.createdAt,
            updatedAt: articles.updatedAt

        }

        setArticles(data);
    }

    const [showAddActionPopUp, setShowAddActionPopUp] = useState(false);

    const handleConcludeAddActionPopUp = async (e) => {
        if(e.status === false){
            setShowAddActionPopUp(false);
        }
        else{
            setShowAddActionPopUp(false);
            handleGetArticleRequest();
        }
    }

    const [showUpdateSitemapPopUp, setShowUpdateSitemapPopUp] = useState(false);
    const [articleSitemapToUpdate, setArticleSitemapToToUpdate] = useState(null);

    const handleShowUpdateSitemapPopUp = async (data) => {
        console.log(data);
        setArticleSitemapToToUpdate(data);
        setShowUpdateSitemapPopUp(true);
    }

    const handleConcludeUpdateSitemapPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSitemapPopUp(false);
        }
        else{
            setShowUpdateSitemapPopUp(false);
            handleGetArticleRequest();
        }
    }

    const [showUpdateActionPopUp, setShowUpdateActionPopUp] = useState(false);
    const [actionToUpdate, setActionToUpdate] = useState();

    const handleShowUpdateActionPopUp = (action_data) => {
        setActionToUpdate(action_data);
        setShowUpdateActionPopUp(true);
    }

    const handleConcludeUpdateActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateActionPopUp(false);
        }
        else{
            setShowUpdateActionPopUp(false);
            handleGetArticleRequest();
        }
    }

    const handleDeleteArticleAction = async (action_id) => {
        console.log(action_id);
        const deleteArticleActionReq = await ArticlePageServices.deleteArticleAction({
            article_id :params.id,
            id:action_id,
            token: user.access_token
        });

        if(deleteArticleActionReq.status === false){
            ToastAlert.notifyError(deleteArticleActionReq.message);
            setArticles([]);
        }
        else{
            handleGetArticleRequest()
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableActionItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={5} isCenter={false} hasPreview={false} lable={value?.name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateActionPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() =>handleDeleteArticleAction(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableActionContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });


    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Article Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the Blog in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate(`seo`)} isBottomAction={false}>
                    {
                        articles?.seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => console.log("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetArticleRequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                articles?.seo?.title != undefined && articles?.seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(articles?.seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.slug != undefined && articles?.seo?.slug != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"slug"} 
                                        description={defaults.capitalize(articles?.seo?.slug)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.description != undefined && articles?.seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(articles?.seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.keywords != undefined && articles?.seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(articles?.seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.canonical_url != undefined && articles?.seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(articles?.seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.twitter_handler != undefined && articles?.seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(articles?.seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                articles?.seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${articles?.seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={articles?.seo?.image?.name?.original}
                                        fileExtension={articles?.seo?.image?.extension?.original}
                                        fileSize={articles?.seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sitemap"} headingActionIcon={icons.addSolidCircle} headingAction={() => handleShowUpdateSitemapPopUp(articles)} isBottomAction={false}>
                        {
                            articles?.sitemap === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Sitemap Details found!"}
                                        description={"Update sitemap details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => console.log(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetArticleRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    articles?.sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"page"} 
                                            description={defaults.capitalize(articles?.sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    articles?.sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${articles?.sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={articles?.sitemap?.image?.name?.original}
                                            fileExtension={articles?.sitemap?.image?.extension?.original}
                                            fileSize={articles?.sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Basic Details"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate(`/page/blog/article/edit/${articles?.id}`)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"title"} 
                                    description={defaults.capitalize(articles?.article?.title)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"description"} 
                                    description={defaults.capitalize(articles?.article?.description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"short description"} 
                                    description={defaults.capitalize(articles?.article?.short_description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Cover Image Mobile`}
                                    preview={`${globals.API_BASE_URL}${articles?.article?.images?.cover_image_mobile?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={articles?.article?.images?.cover_image_mobile?.image_url?.name?.original}
                                    fileExtension={articles?.article?.images?.cover_image_mobile?.extension?.original}
                                    fileSize={articles?.article?.images?.cover_image_mobile?.size?.current}
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Cover Image Web`}
                                    preview={`${globals.API_BASE_URL}${articles?.article?.images?.cover_image_web?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={articles?.article?.images?.cover_image_web?.image_url?.name?.original}
                                    fileExtension={articles?.article?.images?.cover_image_web?.extension?.original}
                                    fileSize={articles?.article?.images?.cover_image_web?.size?.current}
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Thumbnail`}
                                    preview={`${globals.API_BASE_URL}${articles?.article?.images?.thumbnail?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={articles?.article?.images?.thumbnail?.image_url?.name?.original}
                                    fileExtension={articles?.article?.images?.thumbnail?.extension?.original}
                                    fileSize={articles?.article?.images?.thumbnail?.size?.current}
                                />
                            </DetailsRow>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddActionPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={5} lable={"lable"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableActionContainer onSortEnd={handleSortActionEnd} useDragHandle>
                                    {articles?.article?.actions.map((item, index) => (
                                        <SortableActionItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableActionContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
            </PlainContainer>
            {
                showAddActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddActionPopUp({status: false})}>
                    <AddArticleActionPopUp user={user} blog_id={articles.id} concludeAddActionPopUp={(e) => handleConcludeAddActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showUpdateActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Action" closePopUp={() => handleConcludeUpdateActionPopUp({status: false})}>
                    <UpdateArticleActionPopUp user={user} blog_id={articles.id} action_data={actionToUpdate} concludeUpdateActionPopUp={(e) => handleConcludeUpdateActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            {
				showUpdateSitemapPopUp === true 
				? 
				<PopUp width="max" hasBottomPadding={true} heading="Update Sitemap" closePopUp={() => handleConcludeUpdateSitemapPopUp({ status: false })}>
					<UpdateBlogArticlePageSitemapPopUp user={user} sitemap={articleSitemapToUpdate} concludeUpdateSitemapPopUp={(e) => handleConcludeUpdateSitemapPopUp(e) } />
				</PopUp>
			:
				<></>
			}
        </>
    )
}

export default ArticleDetailsPage