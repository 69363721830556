import React from 'react'
import { icons } from '../../../constants'
import { functions } from '../../../utils'

const DetailsItemInnerText = ({ lable, content }) => {
    return (
        <div className="sectionFieldItem">
            
            <div className={`sectionFieldItemLableContent`}>
                <div className="sectionFieldItemLable">
                    <p className="sectionFieldItemLableTxt">{lable}</p>
                </div>
                <div className="sectionFieldItemContent">
                    <div className={`sectionFieldItemContentTxt`} dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
            </div>
        </div>
    )
}

export default DetailsItemInnerText