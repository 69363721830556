import axios from "axios";
import { statusCodes, defaults } from "../../../utils";

const config = (token) => {
  return {
    headers: {
      Authorization: token,
    },
  };
};

const updateSEO = async (payload) => {
  if (!payload.token) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Token is required.",
    });

    return response;
  }

  const data = {
    title: payload.title,
    description: payload.description,
    keywords: payload.keywords,
    canonical_url: payload.canonical_url,
    image: payload.image,
    twitter_handler: payload.twitter_handler,
  };

  try {
    let headers = await config(payload.token);

    const updateSEOReq = await axios.post(
      `${defaults.API_BASE_URL}page/uniforms/seo`,
      data,
      headers
    );
    if (updateSEOReq.status === statusCodes.SUCCESS) {
      const response = defaults.ServiceResponse({
        status: true,
        status_code: statusCodes.SUCCESS,
        message: "SEO successfully updated.",
        data: updateSEOReq?.data?.data,
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to unexpected technical error.",
      });

      return response;
    }
  } catch (error) {
    if (error.response.status === statusCodes.UNAUTHORISED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.UNAUTHORISED,
        message: "Please provide your token.",
      });

      return response;
    } else if (error.response.status === statusCodes.FORBIDDEN) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.FORBIDDEN,
        message: "User doesn't have required rights to process this request.",
      });

      return response;
    } else if (error.response.status === statusCodes.VALIDATION_FAILED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.VALIDATION_FAILED,
        message: "Invalid Inputs",
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to expected technical error.",
      });

      return response;
    }
  }
};

const getAuthSEO = async (payload) => {
  if (!payload.token) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Token is required.",
    });

    return response;
  }
  try {
    let headers = await config(payload.token);

    const getAuthSEOReq = await axios.get(
      `${defaults.API_BASE_URL}page/uniforms/auth/seo`,
      headers
    );
    if (getAuthSEOReq.status === statusCodes.SUCCESS) {
      const response = defaults.ServiceResponse({
        status: true,
        status_code: statusCodes.SUCCESS,
        message: "SEO successfully recieved.",
        data: getAuthSEOReq?.data?.data,
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to unexpected technical error.",
      });

      return response;
    }
  } catch (error) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.BAD_REQUEST,
      message: "Request failed due to expected technical error.",
    });

    return response;
  }
};
const updateSitemap = async (payload) => {
  if (!payload.page) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Page is required.",
    });

    return response;
  }

  if (!payload.image) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Image is required.",
    });

    return response;
  }

  if (!payload.token) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Token is required.",
    });

    return response;
  }

  const data = {
    page: payload.page,
    image: payload.image,
  };

  try {
    let headers = await config(payload.token);

    const updateSitemapReq = await axios.post(
      `${defaults.API_BASE_URL}page/uniforms/sitemap`,
      data,
      headers
    );
    if (updateSitemapReq.status === statusCodes.SUCCESS) {
      const response = defaults.ServiceResponse({
        status: true,
        status_code: statusCodes.SUCCESS,
        message: "Sitemap successfully updated.",
        data: updateSitemapReq?.data?.data,
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to unexpected technical error.",
      });

      return response;
    }
  } catch (error) {
    if (error.response.status === statusCodes.UNAUTHORISED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.UNAUTHORISED,
        message: "Please provide your token.",
      });

      return response;
    } else if (error.response.status === statusCodes.FORBIDDEN) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.FORBIDDEN,
        message: "User doesn't have required rights to process this request.",
      });

      return response;
    } else if (error.response.status === statusCodes.VALIDATION_FAILED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.VALIDATION_FAILED,
        message: "Invalid Inputs",
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to expected technical error.",
      });

      return response;
    }
  }
};

const getAuthSitemap = async (payload) => {
  if (!payload.token) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Token is required.",
    });

    return response;
  }
  try {
    let headers = await config(payload.token);

    const getAuthSitemapReq = await axios.get(
      `${defaults.API_BASE_URL}page/uniforms/auth/sitemap`,
      headers
    );
    if (getAuthSitemapReq.status === statusCodes.SUCCESS) {
      const response = defaults.ServiceResponse({
        status: true,
        status_code: statusCodes.SUCCESS,
        message: "Sitemap successfully recieved.",
        data: getAuthSitemapReq?.data?.data,
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to unexpected technical error.",
      });

      return response;
    }
  } catch (error) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.BAD_REQUEST,
      message: "Request failed due to expected technical error.",
    });

    return response;
  }
};

const saveProduct = async (payload) => {
  if (!payload.token) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Token is required.",
    });

    return response;
  }
  if (!payload.name) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "Name is required.",
    });

    return response;
  }
  if (!payload.url) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "url is required.",
    });

    return response;
  }
  if (!payload.title) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "title is required.",
    });

    return response;
  }
  if (!payload.short_description) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "short_description is required.",
    });

    return response;
  }
  if (!payload.description) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "description is required.",
    });

    return response;
  }
  if (!payload.image) {
    const response = defaults.ServiceResponse({
      status: false,
      status_code: statusCodes.VALIDATION_FAILED,
      message: "image is required.",
    });

    return response;
  }

  const data = {
    name: payload.name,
    url: payload.url,
    title: payload.title,
    short_description: payload.short_description,
    description: payload.description,
    image: payload.image,
  };

  try {
    let headers = await config(payload.token);

    const saveProudctReq = await axios.post(
      `${defaults.API_BASE_URL}product`,
      data,
      headers
    );
    if (saveProudctReq.status === statusCodes.SUCCESS) {
      const response = defaults.ServiceResponse({
        status: true,
        status_code: statusCodes.SUCCESS,
        message: "Product successfully saved.",
        data: saveProudctReq?.data?.data,
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to unexpected technical error.",
      });

      return response;
    }
  } catch (error) {
    if (error.response.status === statusCodes.UNAUTHORISED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.UNAUTHORISED,
        message: "Please provide your token.",
      });

      return response;
    } else if (error.response.status === statusCodes.FORBIDDEN) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.FORBIDDEN,
        message: "User doesn't have required rights to process this request.",
      });

      return response;
    } else if (error.response.status === statusCodes.VALIDATION_FAILED) {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.VALIDATION_FAILED,
        message: "Invalid Inputs",
      });

      return response;
    } else {
      const response = defaults.ServiceResponse({
        status: false,
        status_code: statusCodes.BAD_REQUEST,
        message: "Request failed due to expected technical error.",
      });

      return response;
    }
  }
};

const deleteUniformProducts = async (payload) => {
  if(!payload.id){

      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: " ID is required.",
      });

      return response
  }
  
  if(!payload.token){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });

      return response
  }



  try{
      let headers = await config(payload.token);

      const deleteUniformProductsReq = await axios.delete(`${defaults.API_BASE_URL}product/${payload.id}`,headers);
      if(deleteUniformProductsReq.status === statusCodes.SUCCESS){
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Products successfully deleted.",
              data: deleteUniformProductsReq?.data?.data
          });
  
          return response
      }
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error."
          });
  
          return response
      }
  }
  catch(error){
      if(error.response.status === statusCodes.UNAUTHORISED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.FORBIDDEN){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.VALIDATION_FAILED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
  
          return response
      }
      else{
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error."
          });
  
          return response
      }
  }
}

const UniformsPageServices = {
  updateSEO,
  getAuthSEO,
  updateSitemap,
  getAuthSitemap,
  saveProduct,
  deleteUniformProducts
};

export default UniformsPageServices;
