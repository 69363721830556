import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { HeaderServices } from '../../../services';
import AddSubHeaderActionPopUp from '../../../components/dashboard/popups/Header/AddSubHeaderActionPopUp';
import PopUp from '../../../components/core/popup';
import SelectInput from '../../../components/core/form/SelectInput';
import AddParentNavigationItemPopUp from '../../../components/dashboard/popups/Header/AddParentNavigationItemPopUp';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import UpdateHeaderActionPopUp from '../../../components/dashboard/popups/Header/UpdateHeaderActionPopUp';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateSubHeaderActionPopUp from '../../../components/dashboard/popups/Header/UpdateSubHeaderActionPopup';
import UpdateParentNavigationItemPopUp from '../../../components/dashboard/popups/Header/UpdateParentNavigationItemPopup';
import AddSubParentNavigationItemPopUp from '../../../components/dashboard/popups/Header/AddSubParentNavigationItemPopUp';
import UpdateSubParentNavigationItemPopUp from '../../../components/dashboard/popups/Header/UpdateSubParentNavigationItemPopUp';
  
const SortableParentNavigationContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const SortableSubParentNavigationContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const HeaderPage = ({ user }) => {
    const navigate = useNavigate();

    const [parent, setParent] = useState(0);
    const [parentData, setParentData] = useState(null);
    const [hasParentMessage, setHasParentMessage] = useState(false);
    const [parentMessage, setParentMessage] = useState("");
    const [parentMessageType, setParentMessageType] = useState("");

    const [subHeaderActions, setSubHeaderActions] = useState([]);

    const [parents ,setParents] =  useState([])

    const handleGetParentsRequest = async () => {
        const getParentsReq = await HeaderServices.getAllParentItems({
            token: user.access_token,
        });

        if(getParentsReq.status === false){
            ToastAlert.notifyError(getParentsReq.message);
            setParents([]);
        }
        else{
            console.log("parents view",getParentsReq?.data)
            setParents(getParentsReq.data);
           
        }
    }

    useEffect(()=>{
        handleGetParentsRequest()
    },[])

    const handleGetSubHeaderActionsRequest = async () => {
        const getSubHeaderActionsReq = await HeaderServices.getAuthSubHeaderActions({
            token: user.access_token
        });

        if(getSubHeaderActionsReq.status === false){
            ToastAlert.notifyError(getSubHeaderActionsReq.message);
            setSubHeaderActions([]);
        }
        else{
            setSubHeaderActions(getSubHeaderActionsReq.data);
        }
    }

    useEffect(() => {
        handleGetSubHeaderActionsRequest();
    }, []);

    const [showAddSubHeaderActionPopUp, setShowAddSubHeaderActionPopUp] = useState(false);

    const handleConcludeAddLeftActionPopUp = async (e) => {
        if(e.status === false){
            setShowAddSubHeaderActionPopUp(false);
        }
        else{
            setShowAddSubHeaderActionPopUp(false);
            handleGetSubHeaderActionsRequest();
        }
    }

    const [showUpdateSubHeaderActionsPopUp, setShowUpdateSubHeaderActionsPopUp] = useState(false);
    const [subHeaderActionsToUpdate, setSubHeaderActionsToUpdate] = useState(null);

    const handleEditSubHeaderAction = async (data) => {
       setShowUpdateSubHeaderActionsPopUp(true)
       setSubHeaderActionsToUpdate(data)
    }

    const handleConcludeUpdateSubHeaderActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSubHeaderActionsPopUp(false);
        }
        else{
            setShowUpdateSubHeaderActionsPopUp(false);
            handleGetSubHeaderActionsRequest();
        }
    }
     

    const handleDeleteSubHeaderAction = async (action_id) => {
        console.log(action_id);
        const deleteSubHeaderActionReq = await HeaderServices.deleteSubHeaderAction({
            id:action_id,
            token: user.access_token
        });

        if(deleteSubHeaderActionReq.status === false){
            ToastAlert.notifyError(deleteSubHeaderActionReq.message);
            setSubHeaderActions([]);
        }
        else{
            handleGetSubHeaderActionsRequest()
        }
    }

    const [parentNavigationItems, setParentNavigationItems] = useState([]);

    const handleGetParentNavigationItemsRequest = async () => {
        const getParentNavigationItemsReq = await HeaderServices.getAuthParentNavigationItems({
            token: user.access_token
        });

        if(getParentNavigationItemsReq.status === false){
            ToastAlert.notifyError(getParentNavigationItemsReq.message);
            setParentNavigationItems([]);
        }
        else{
            console.log(getParentNavigationItemsReq.data);
            setParentNavigationItems(getParentNavigationItemsReq.data);
        }
    }

    useEffect(() => {
        handleGetParentNavigationItemsRequest();
    }, []);

    const [showAddParentNavigationItemPopUp, setShowAddParentNavigationItemPopUp] = useState(false);

    const handleConcludeAddParentNavigationItemPopUp = async (e) => {
        if(e.status === false){
            setShowAddParentNavigationItemPopUp(false);
        }
        else{
            setShowAddParentNavigationItemPopUp(false);
            handleGetParentNavigationItemsRequest();
        }
    }
 
    const [showUpdateParentNavigationPopUp, setShowUpdateParentNavigationPopUp] = useState(false);
    const [parentNavigationToUpdate, setParentNavigationToUpdate] = useState(null);
    
    const handleEditParentNavigation = async (data) => {
        setShowUpdateParentNavigationPopUp(true)
        setParentNavigationToUpdate(data)
    }
    
    const handleConcludeUpdateParentNavigationPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateParentNavigationPopUp(false);
        }
        else{
            setShowUpdateParentNavigationPopUp(false);
            handleGetParentNavigationItemsRequest();
        }
    }
    
    const handleDeleteParentNavigation = async (action_id) => {
        const deleteParentNavigationReq = await HeaderServices.deleteParentNavigation({
            id:action_id,
            token: user.access_token
        });

        if(deleteParentNavigationReq.status === false){
            ToastAlert.notifyError(deleteParentNavigationReq.message);
            setParentNavigationItems([]);
        }
        else{
            handleGetParentNavigationItemsRequest()
        }
    }
    
    const [headerAction, setHeaderAction] = useState(null);

    const [showUpdateHeaderActionPopUp, setShowUpdateHeaderActionPopUp] = useState(false);

    const handleGetHeaderActionRequest = async () => {
        const getHeaderActionReq = await HeaderServices.getAuthHeaderAction({
            token: user.access_token
        });

        if(getHeaderActionReq.status === false){
            ToastAlert.notifyError(getHeaderActionReq.message);
            setHeaderAction(null);
        }
        else{
            console.log(getHeaderActionReq.data);
            setHeaderAction(getHeaderActionReq.data);
        }
    }

    useEffect(() => {
        handleGetHeaderActionRequest();
    }, []);

    const handleConcludeUpdateHeaderActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateHeaderActionPopUp(false);
        }
        else{
            setShowUpdateHeaderActionPopUp(false);
            handleGetHeaderActionRequest();
        }
    }
    
    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableParentNavigationItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
            <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
            <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} hasChildren={true}>
                <div className="clickableCountTable">
                    <p className="clickableCountTableTxt">{value?.sub_items.length}</p>
                </div>
            </TableRowItem>
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleEditParentNavigation(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() =>handleDeleteParentNavigation(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));

    const handleSaveSortedParentNavigationList = async (list) => {

        const sortParentNavigationReq = await HeaderServices.sortParentNavigationItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortParentNavigationReq.status === false){
            ToastAlert.notifyError(sortParentNavigationReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortParentNavigationReq.message);
            handleGetParentNavigationItemsRequest();
        }
    }

    const handleSortParentNavigationEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(parentNavigationItems, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedParentNavigationList(entityIdWithIndex);

        setParentNavigationItems([...arrayMove]);
    }

    const [subParentNavigationItems, setSubParentNavigationItems] = useState([]);

    const handleGetSubParentNavigationItemsRequest = async () => {
        const getSubParentNavigationItemsReq = await HeaderServices.getAuthSubParentNavigationItems({
            token: user.access_token,
            id: parent
        });

        if(getSubParentNavigationItemsReq.status === false){
            ToastAlert.notifyError(getSubParentNavigationItemsReq.message);
            setSubParentNavigationItems([]);
        }
        else{
            console.log("parent items",getSubParentNavigationItemsReq)
            setParentData(getSubParentNavigationItemsReq.data.parent);
            setSubParentNavigationItems(getSubParentNavigationItemsReq.data.navigation);
        }
    }

    useEffect(() => {
        // handleGetSubParentNavigationItemsRequest();
        if(parent !== 0){
            handleGetSubParentNavigationItemsRequest();
        }
    }, [parent]);

    const [showAddSubParentNavigationItemPopUp, setShowAddSubParentNavigationItemPopUp] = useState(false);

    const handleConcludeAddSubParentNavigationItemPopUp = async (e) => {
        if(e.status === false){
            setShowAddSubParentNavigationItemPopUp(false);
        }
        else{
            setShowAddSubParentNavigationItemPopUp(false);
            handleGetSubParentNavigationItemsRequest();
        }
    }
 
    const [showUpdateSubParentNavigationPopUp, setShowUpdateSubParentNavigationPopUp] = useState(false);
    const [subParentNavigationToUpdate, setSubParentNavigationToUpdate] = useState(null);
    
    const handleEditSubParentNavigation = async (data) => {
        setShowUpdateSubParentNavigationPopUp(true)
        setSubParentNavigationToUpdate(data)
    }
    
    const handleConcludeUpdateSubParentNavigationPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSubParentNavigationPopUp(false);
        }
        else{
            setShowUpdateSubParentNavigationPopUp(false);
            handleGetSubParentNavigationItemsRequest();
        }
    }
    
    const handleDeleteSubParentNavigation = async (action_id) => {
        const deleteSubParentNavigationReq = await HeaderServices.deleteSubParentNavigation({
            parent_id: parentData.id,
            id: action_id,
            token: user.access_token
        });

        if(deleteSubParentNavigationReq.status === false){
            ToastAlert.notifyError(deleteSubParentNavigationReq.message);
            setSubParentNavigationItems([]);
        }
        else{
            handleGetSubParentNavigationItemsRequest()
        }
    }

const SortableSubParentNavigationItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={parentData?.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleEditSubParentNavigation(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() =>handleDeleteSubParentNavigation(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));

    const handleSaveSortedSubParentNavigationList = async (list) => {

        const sortSubParentNavigationReq = await HeaderServices.sortSubParentNavigationItems({
            items: JSON.stringify(list),
            id: parent,
            token: user.access_token
        });

        if(sortSubParentNavigationReq.status === false){
            ToastAlert.notifyError(sortSubParentNavigationReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortSubParentNavigationReq.message);
            handleGetSubParentNavigationItemsRequest();
        }
    }

    const handleSortSubParentNavigationEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(subParentNavigationItems, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedSubParentNavigationList(entityIdWithIndex);

        setSubParentNavigationItems([...arrayMove]);
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Header'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to header in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Sub header Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSubHeaderActionPopUp(true)} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <Table>
                            <TableHeading>
                                <TableHeadingItem size={3} lable={"lable"} />
                                <TableHeadingItem size={1} lable={"icon"} />
                                <TableHeadingItem size={2} lable={"created by"} />
                                <TableHeadingItem size={2} lable={"Actions"} />
                            </TableHeading>
                            <TableRows>
                                {
                                    subHeaderActions.map((action, i) => {
                                        return (
                                            <TableRow isCenter={false} key={i}>
                                                <TableRowItem size={3} isCenter={false} hasPreview={false} isCapital={false} lable={action?.lable} />
                                                <TableRowItem size={1} isCenter={false} hasPreview={false} hasChildren={true}>
                                                    <PlainContainer styles={{paddingLeft: "15px"}}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={action?.icon} color={"dark"} clickable={false} />
                                                        </TableRowIcons>
                                                    </PlainContainer>
                                                </TableRowItem>
                                                <TableRowItem size={2} isCenter={false} hasPreview={false} lable={action?.created_by?.first_name} />
                                                <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                    <TableRowActions>
                                                        <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleEditSubHeaderAction(action)}>edit</TableButton>
                                                        <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteSubHeaderAction(action.id)}>delete</TableButton>
                                                    </TableRowActions>
                                                </TableRowItem>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableRows>
                        </Table>
                    </PlainContainer>
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Parent Navigation"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddParentNavigationItemPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"lable"} />
                                    <TableHeadingItem size={1} lable={"go to type"} />
                                    <TableHeadingItem size={2} lable={"go to"} />
                                    <TableHeadingItem size={1} lable={"sub items"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableParentNavigationContainer onSortEnd={handleSortParentNavigationEnd} useDragHandle>
                                    {parentNavigationItems.map((item, index) => (
                                        <SortableParentNavigationItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableParentNavigationContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"sub Navigation"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSubParentNavigationItemPopUp(true)} isBottomAction={false}>
                    
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <SplitContainer>
                                <SelectInput 
                                    hasLable={true}
                                    lable="Parent Navigation"
                                    isMandatory={true}
                                    hasMessage={hasParentMessage}
                                    message={parentMessage}
                                    messageType={parentMessageType}
                                >
                                    <select className="cmsFormStepInputSelect" value={parent} onChange={(e) => setParent(e.target.value)}>
                                        <option value={0} disabled={false}>Select Parent Navigation</option>
                                        {
                                            parents.map((pt) => {
                                                return (
                                                    <option key={pt.id} value={pt.id}>{pt?.lable}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </SelectInput>
                            </SplitContainer>
                       
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"lable"} />
                                    <TableHeadingItem size={2} lable={"Parent"} />
                                    <TableHeadingItem size={1} lable={"go to type"} />
                                    <TableHeadingItem size={2} lable={"go to"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableSubParentNavigationContainer onSortEnd={handleSortSubParentNavigationEnd} useDragHandle>
                                    {subParentNavigationItems.map((item, index) => (
                                        <SortableSubParentNavigationItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableSubParentNavigationContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Header Action"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateHeaderActionPopUp(true)} isBottomAction={false}>
                        
                        {
                            headerAction === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No header actions found!"}
                                        description={"Update header actions by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateHeaderActionPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"lable"} 
                                        description={defaults.capitalize(headerAction?.lable)} 
                                    />
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"value"} 
                                        description={defaults.capitalize(headerAction?.value)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${headerAction?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={headerAction?.image?.name?.original}
                                        fileExtension={headerAction?.image?.extension?.original}
                                        fileSize={headerAction?.image?.size?.current}
                                    />
                                </DetailsRow>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddSubHeaderActionPopUp === true
                ?
                <PopUp width="max" heading="Create Sub Header Action" closePopUp={() => handleConcludeAddLeftActionPopUp({status: false})}>
                    <AddSubHeaderActionPopUp user={user} concludeAddActionPopUp={(e) => handleConcludeAddLeftActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddParentNavigationItemPopUp === true
                ?
                <PopUp width="max" heading="Create Parent Navigation Item" closePopUp={() => handleConcludeAddParentNavigationItemPopUp({status: false})}>
                    <AddParentNavigationItemPopUp user={user} concludeAddParentNavigationItemPopUp={(e) => handleConcludeAddParentNavigationItemPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddSubParentNavigationItemPopUp === true
                ?
                <PopUp width="max" heading="Create  Sub Parent Navigation Item" closePopUp={() => handleConcludeAddSubParentNavigationItemPopUp({status: false})}>
                    <AddSubParentNavigationItemPopUp user={user} concludeAddSubParentNavigationItemPopUp={(e) => handleConcludeAddSubParentNavigationItemPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateHeaderActionPopUp === true
                ?
                <PopUp width="max" heading="Update Header Action" closePopUp={() => handleConcludeUpdateHeaderActionPopUp({status: false})}>
                    <UpdateHeaderActionPopUp user={user} header_action={headerAction} concludeUpdateHeaderActionPopUp={(e) => handleConcludeUpdateHeaderActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            {
                showUpdateSubHeaderActionsPopUp === true
                ?
                <PopUp width="max" heading="Update Sub Header Action" closePopUp={() => handleConcludeUpdateSubHeaderActionPopUp({status: false})}>
                    <UpdateSubHeaderActionPopUp user={user} data={subHeaderActionsToUpdate} concludeUpdateSubHeaderActionPopUp={(e) => handleConcludeUpdateSubHeaderActionPopUp(e)} />
                </PopUp>
                :
                <></>
             }
              {
                showUpdateParentNavigationPopUp === true
                ?
                <PopUp width="max" heading="Update Parent Navigation Action" closePopUp={() => handleConcludeUpdateParentNavigationPopUp({status: false})}>
                    <UpdateParentNavigationItemPopUp user={user} data={parentNavigationToUpdate} concludeUpdateParentNavigationPopUp={(e) => handleConcludeUpdateParentNavigationPopUp(e)} />
                </PopUp>
                :
                <></>
             }

            {
                showUpdateSubParentNavigationPopUp === true
                ?
                <PopUp width="max" heading="Update Parent Navigation Action" closePopUp={() => handleConcludeUpdateSubParentNavigationPopUp({status: false})}>
                    <UpdateSubParentNavigationItemPopUp user={user} data={subParentNavigationToUpdate} concludeUpdateSubParentNavigationPopUp={(e) => handleConcludeUpdateSubParentNavigationPopUp(e)} />
                </PopUp>
                :
                <></>
             }
        </>
    )
}

export default HeaderPage