import React, { useState } from 'react'
import TextInputRT from '../../../core/form/TextInputRT'
import { icons, inputs } from '../../../../constants';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { HeaderServices } from '../../../../services';

const UpdateSubHeaderActionPopUp = ({ user,  data,concludeUpdateSubHeaderActionPopUp }) => {
  const id=data.id

    const [lable, setLable] = useState(data != null ? data.lable : "");
    const [hasLableMessage, setHasLableMessage] = useState(false);
    const [lableMessage, setLableMessage] = useState("");
    const [lableMessageType, setLableMessageType] = useState("");

    const [icon, setIcon] = useState(data != null ? data.icon : "");
    const [hasIconMessage, setHasIconMessage] = useState(false);
    const [iconMessage, setIconMessage] = useState("");
    const [iconMessageType, setIconMessageType] = useState("");

    const [action, setAction] = useState(data != null ? data.action : "");
    const [hasActionMessage, setHasActionMessage] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [actionMessageType, setActionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSubHeaderAction = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(lable === false){
            setHasLableMessage(true);
            setLableMessage("Invalid Lable");
            setLableMessageType("error");
        }
        else{
            setHasLableMessage(false);
            setLableMessage("");
            setLableMessageType("");
        }

        if(icon === ""){
            // setHasKnNameIcon(true);
            // setKnNameIcon(icons.wrongCircle);
            // setKnNameIconType("error");
            setHasIconMessage(true);
            setIconMessage("Invalid Icon");
            setIconMessageType("error");
        }
        else{
            // setHasKnNameIcon(true);
            // setKnNameIcon(icons.tickCircle);
            // setKnNameIconType("success");
            setHasIconMessage(false);
            setIconMessage("");
            setIconMessageType("");
        }

        if(lable === "" || icon === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const data ={
                id:id,
                lable: lable,
                icon: icon,
                action: action,
                token: user.access_token
            }


            const updateSubHeaderActionReq = await HeaderServices.updateSubHeader(data);

            console.log("after update",data);

            if(updateSubHeaderActionReq.status === false){
                ToastAlert.notifyError(updateSubHeaderActionReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(updateSubHeaderActionReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSubHeaderActionPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSubHeaderAction(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.subHeaderActionLable.hasLable}
                        lable={inputs.subHeaderActionLable.lable}
                        isMandatory={inputs.subHeaderActionLable.is_mandatory}
                        hasMessage={hasLableMessage}
                        message={lableMessage}
                        messageType={lableMessageType}
                        isLimited={inputs.subHeaderActionLable.is_limited}
                        limit={inputs.subHeaderActionLable.max_characters}
                        value={lable}
                    >
                        <input 
                            type={inputs.subHeaderActionLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.subHeaderActionLable.placeholder}
                            onChange={(e) => setLable(e.target.value)}
                            value={lable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.subHeaderActionIcon.hasLable}
                        lable={inputs.subHeaderActionIcon.lable}
                        isMandatory={inputs.subHeaderActionIcon.is_mandatory}
                        hasMessage={hasIconMessage}
                        message={iconMessage}
                        messageType={iconMessageType}
                        isLimited={inputs.subHeaderActionIcon.is_limited}
                        limit={inputs.subHeaderActionIcon.max_characters}
                        value={icon}
                    >
                        <input 
                            type={inputs.subHeaderActionIcon.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.subHeaderActionIcon.placeholder}
                            onChange={(e) => setIcon(e.target.value)}
                            value={icon}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.subHeaderActionURL.hasLable}
                        lable={inputs.subHeaderActionURL.lable}
                        isMandatory={inputs.subHeaderActionURL.is_mandatory}
                        hasMessage={hasActionMessage}
                        message={actionMessage}
                        messageType={actionMessageType}
                        isLimited={inputs.subHeaderActionURL.is_limited}
                        limit={inputs.subHeaderActionURL.max_characters}
                        value={action}
                    >
                        <input 
                            type={inputs.subHeaderActionURL.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.subHeaderActionURL.placeholder}
                            onChange={(e) => setAction(e.target.value)}
                            value={action}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSubHeaderActionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateSubHeaderActionPopUp