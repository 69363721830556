import React, { useEffect, useState, forwardRef, useRef, useMemo  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import TextInputRT from '../../../../../components/core/form/TextInputRT';
import TextAreaInput from '../../../../../components/core/form/TextAreaInput';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import { defaults, ToastAlert, functions, globals, validations } from '../../../../../utils';
import { ProductServices } from '../../../../../services';
import CardHeading from '../../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../../components/core/typography/dashboard/CardDescription';
import FileUploadInput from '../../../../../components/core/form/FileUploadInput';
import FormActions from '../../../../../components/form/FormActions';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddProductPage = ({ user }) => {
    const navigate = useNavigate();

    const quillRef = useRef();

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [
                    { 'header': [1, 2, 3, 4, 5, 6, false] }
                ],
                ['bold', 'italic', 'underline', "strike"],
                // ['image', "link"],
                // [
                //     { 'list': 'ordered' }, { 'list': 'bullet' },
                //     { 'indent': '-1' }, { 'indent': '+1' }
                // ],
                [{ 'align': [] }],
                // [
                //     { 'color': 
                //         ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
                //     }
                // ]
            ],
            // handlers: {
            //     image: imageHandler
            // }
        },
    }), []);

    const [name, setName] = useState("");
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [url, setURL] = useState("");
    const [hasURLIcon, setHasURLIcon] = useState(false);
    const [urlIcon, setURLIcon] = useState("");
    const [urlIconPosition, setURLIconPosition] = useState("right");
    const [urlIconType, setURLIconType] = useState("");
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [isURLValid, setIsURLValid] = useState(false);

    const [title, setTitle] = useState("");
    const [hasTitleIcon, setHasTitleIcon] = useState(false);
    const [titleIcon, setTitleIcon] = useState("");
    const [titleIconPosition, setTitleIconPosition] = useState("right");
    const [titleIconType, setTitleIconType] = useState("");
    const [hasTitleMessage, setHasTitleMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState("");
    const [titleMessageType, setTitleMessageType] = useState("");

    const [shortDescription, setShortDescription] = useState("");
    const [hasShortDescriptionIcon, setHasShortDescriptionIcon] = useState(false);
    const [shortDescriptionIcon, setShortDescriptionIcon] = useState("");
    const [shortDescriptionIconPosition, setShortDescriptionIconPosition] = useState("right");
    const [shortDescriptionIconType, setShortDescriptionIconType] = useState("");
    const [hasShortDescriptionMessage, setHasShortDescriptionMessage] = useState(false);
    const [shortDescriptionMessage, setShortDescriptionMessage] = useState("");
    const [shortDescriptionMessageType, setShortDescriptionMessageType] = useState("");

    const [description, setDescription] = useState("");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const pathChanged = async (e) => {
        if(e.length > 0){
            setURL(e);

            const checkPathAvailabilityReq = await ProductServices.checkProductPathAvailability({
                path: e,
                token: user.access_token
            });
    
            if(checkPathAvailabilityReq.status === false){
                if(checkPathAvailabilityReq.status_code === 403){
                    setHasURLMessage(true);
                    setURLMessage("Page with same path already exists.");
                    setURLMessageType("error");
                    setSubmitButtonDisabled(true);
                    setIsURLValid(false);
                }
                else{
                    ToastAlert.notifyError(checkPathAvailabilityReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setIsURLValid(false);
                }
            }
            else{
                setHasURLMessage(false);
                setURLMessage("");
                setURLMessageType("");
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(false);
                setIsURLValid(true);
            }
        }
        else{
            setURL(e);
            setSubmitButtonDisabled(true);
        }
    }

    const handleAddProductSubmission = async (e) => {
        e.preventDefault();

        const isNameValid = await validations.validateAllName(name);

        if(isNameValid.status === false){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(isURLValid === false){
            setHasURLIcon(true);
            setURLIcon(icons.wrongCircle);
            setURLIconType("error");
            setHasURLMessage(true);
            setURLMessage("Invalid URL");
            setURLMessageType("error");
        }
        else{
            setHasURLIcon(true);
            setURLIcon(icons.tickCircle);
            setURLIconType("success");
            setHasURLMessage(false);
            setURLMessage("");
            setURLMessageType("");
        }

        if(title.trim() === ""){
            setHasTitleIcon(true);
            setTitleIcon(icons.wrongCircle);
            setTitleIconType("error");
            setHasTitleMessage(true);
            setTitleMessage("Invalid Title");
            setTitleMessageType("error");
        }
        else{
            setHasTitleIcon(true);
            setTitleIcon(icons.tickCircle);
            setTitleIconType("success");
            setHasTitleMessage(false);
            setTitleMessage("");
            setTitleMessageType("");
        }

        if(shortDescription.trim() === ""){
            setHasShortDescriptionIcon(true);
            setShortDescriptionIcon(icons.wrongCircle);
            setShortDescriptionIconType("error");
            setHasShortDescriptionMessage(true);
            setShortDescriptionMessage("Invalid Short Description");
            setShortDescriptionMessageType("error");
        }
        else{
            setHasShortDescriptionIcon(true);
            setShortDescriptionIcon(icons.tickCircle);
            setShortDescriptionIconType("success");
            setHasShortDescriptionMessage(false);
            setShortDescriptionMessage("");
            setShortDescriptionMessageType("");
        }

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        const saveProductReq = await ProductServices.saveProduct({
            name: name,
            url: url,
            title: title,
            short_description: shortDescription,
            description: description,
            image: selectedImages[0],
            token: user.access_token
        });

        if(saveProductReq.status === false){
            ToastAlert.notifyError(saveProductReq.message);
            setSubmitButtonLoading(false);
        }
        else{
            ToastAlert.notifySuccess(saveProductReq.message);
            setSubmitButtonLoading(false);
            setSubmitButtonDisabled(true);
            setSubmitButtonBg('success');
            setSubmitButtonHasIcon(true);
            setSubmitButtonLable('Success');
            navigate(-1);
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Add Product'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Add new product/uniform to display in the website'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"Create Product"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddProductSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Name"
                                        isMandatory={false}
                                        hasIcon={hasNameIcon}
                                        icon={nameIcon}
                                        iconPosition={nameIconPosition}
                                        iconType={nameIconType}
                                        hasMessage={hasNameMessage}
                                        message={nameMessage}
                                        messageType={nameIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Page Name"}
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                        />
                                    </TextInputRT>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="URL"
                                        isMandatory={false}
                                        hasIcon={hasURLIcon}
                                        icon={urlIcon}
                                        iconPosition={urlIconPosition}
                                        iconType={urlIconType}
                                        hasMessage={hasURLMessage}
                                        message={urlMessage}
                                        messageType={urlMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter URL"}
                                            onChange={(e) => pathChanged(e.target.value)}
                                            value={url}
                                        />
                                    </TextInputRT>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Title"
                                        isMandatory={false}
                                        hasIcon={hasTitleIcon}
                                        icon={titleIcon}
                                        iconPosition={titleIconPosition}
                                        iconType={titleIconType}
                                        hasMessage={hasTitleMessage}
                                        message={titleMessage}
                                        messageType={titleIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Page Title"}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </TextInputRT>
                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"short description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasShortDescriptionMessage}
                                        message={shortDescriptionMessage}
                                        messageType={shortDescriptionMessageType}
                                    >
                                        <textarea 
                                            type="text" 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter Short Description"}
                                            onChange={(e) => setShortDescription(e.target.value)}
                                            rows={3}
                                            value={shortDescription}
                                        >
                                        </textarea>
                                    </TextAreaInput>

                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasDescriptionMessage}
                                        message={descriptionMessage}
                                        messageType={descriptionMessageType}
                                    >
                                        <ReactQuill modules={modules} ref={quillRef} style={{height: '400px', paddingBottom: '45px'}} theme="snow" value={description} onChange={setDescription} />
                                    </TextAreaInput>
                                    

                                    <FileUploadInput 
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />

                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddProductPage
