import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import DetailsRow from '../../../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../../../components/core/containers/DetailsItem';
import DetailsItemWithPreview from '../../../../../components/core/containers/DetailsItemWithPreview';
import Table from '../../../../../components/core/table/containers/Table';
import TableHeading from '../../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../../../utils';
import { ProductServices } from '../../../../../services';
import PopUp from '../../../../../components/core/popup';
import NoDataContainer from '../../../../../components/core/containers/NoDataContainer';
import DetailsItemInnerText from '../../../../../components/core/containers/DetailsItemInnerText';
import AddProductHighlightPopUp from '../../../../../components/dashboard/popups/Product/AddProductHighlightPopUp';
import UpdateProductHighlightPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductHighlightPopUp';
import AddProductActionPopUp from '../../../../../components/dashboard/popups/Product/AddProductActionPopUp';
import UpdateProductActionPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductActionPopUp';
import AddProductSubProductsPopUp from '../../../../../components/dashboard/popups/Product/AddProductSubProductsPopup';
import UpdateProductSubProductsPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductsSubProductsPopup';
import UpdateUniformsProductPageSitemapPopUp from '../../../../../components/dashboard/popups/Pages/UniformsPage/UpdateUniformsProductPageSitemapPopup';
import AddSubProductImagePopUp from '../../../../../components/dashboard/popups/SubProduct/AddSubProductImagePopUp';
import SubProductServices from '../../../../../services/Common/SubProduct';
import UpdateSubProductImagePopUp from '../../../../../components/dashboard/popups/SubProduct/UpdateSubProductImagePopUp';

const SubProductDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const { id: productId, subProductId } = useParams();

    const [subProduct, setSubProduct] = useState(null);

    const handleGetSubProductRequest = async () => {
        const getSubProductReq = await SubProductServices.getThisAuthSubProduct({
            id: subProductId,
            product_id: productId,
            token: user.access_token
        });

        if(getSubProductReq.status === false){
            ToastAlert.notifyError(getSubProductReq.message);
            setSubProduct(null);
        }
        else{
            console.log("sub products success",getSubProductReq.data);
            setSubProduct(getSubProductReq?.data);
        }
    }

    useEffect(() => {
        handleGetSubProductRequest();
    }, []);

    const [showAddImagePopUp, setShowAddImagePopUp] = useState(false);

    const handleConcludeAddImagePopUp = async (e) => {
        if(e.status === false){
            setShowAddImagePopUp(false);
        }
        else{
            setShowAddImagePopUp(false);
            handleGetSubProductRequest();
        }
    }

    const [showUpdateImagePopUp, setShowUpdateImagePopUp] = useState(false);
    const [imageToUpdate, setImagetToUpdate] = useState();

    const handleShowUpdateImagePopUp = (data) => {
        setImagetToUpdate(data);
        setShowUpdateImagePopUp(true);
    }

    const handleConcludeUpdateImagePopUp = async (e) => {
        if(e.status === false){
            setShowUpdateImagePopUp(false);
        }
        else{
            setShowUpdateImagePopUp(false);
            handleGetSubProductRequest();
        }
    }

    const handleSaveSortedImageList = async (list) => {

        const sortImageReq = await SubProductServices.sortSubProducts({
            items: JSON.stringify(list),
            sub_product_id: subProductId,
            product_id: productId,
            token: user.access_token
        });

        if(sortImageReq.status === false){
            ToastAlert.notifyError(sortImageReq.message);
        }
        else{
            console.log("image request",sortImageReq)
            ToastAlert.notifySuccess(sortImageReq.message);
            handleGetSubProductRequest();
        }
    }

    const handleSortImageEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(subProduct?.gallery, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedImageList(entityIdWithIndex);

        let data = {
            id: subProduct.id,
            name: subProduct.name,
            description: subProduct.description,
            gallery:[...arrayMove],
            created_by: subProduct.created_by,
            display_order:subProduct.display_order,
            createdAt: subProduct.createdAt,
            updatedAt: subProduct.updatedAt
        }

        setSubProduct(data);
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableImageItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={5} isCenter={false} hasPreview={true}  previewType={'image'} previewItem={`${globals.API_BASE_URL}${value?.image_thumb?.image_url?.full?.high_res}`} lable={value?.name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateImagePopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteSubProductImage(value._id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableImageContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    
    const handleDeleteSubProductImage = async (gallery_id) => {
        console.log(gallery_id);
        const deleteSubProductImagReq = await SubProductServices.deleteSubProductImage({
            sub_product_id: subProductId,
            product_id: productId,
            gallery_id:gallery_id,
            token: user.access_token
        });

        if(deleteSubProductImagReq.status === false){
            ToastAlert.notifyError(deleteSubProductImagReq.message);
            setSubProduct([]);
        }
        else{
            handleGetSubProductRequest()
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading=' Sub Product Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the Sub Product uniform in this page'
                />
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <PlainContainer>
                <Card hasHeading={true} heading={"Basic Details"}  headingAction={() =>console.log("console here")} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"name"} 
                                    description={defaults.capitalize(subProduct?.name)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"description"} 
                                    description={defaults.capitalize(subProduct?.description)} 
                                />
                            </DetailsRow>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Images"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddImagePopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={5} lable={"name"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableImageContainer onSortEnd={handleSortImageEnd} useDragHandle>
                                    {subProduct?.gallery?.map((item, index) => (
                                        <SortableImageItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableImageContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
            </PlainContainer>
            {
                showAddImagePopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Images" closePopUp={() => handleConcludeAddImagePopUp({status: false})}>
                    <AddSubProductImagePopUp user={user}  product_id = {productId} subProduct_id={subProductId} concludeAddImagePopUp={(e) => handleConcludeAddImagePopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateImagePopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Images" closePopUp={() => handleConcludeUpdateImagePopUp({status: false})}>
                    <UpdateSubProductImagePopUp user={user}  product_id = {productId} subProduct_id={subProductId} image={imageToUpdate} concludeUpdateImagePopUp={(e) => handleConcludeUpdateImagePopUp(e)} />
                </PopUp>
                :
                <></>
            }
            
        </>
    )
}

export default SubProductDetailsPage