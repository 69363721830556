import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import Card from '../../../../components/core/dashboard/Card';
import DetailsRow from '../../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../../components/core/containers/DetailsItem';
import { defaults, ToastAlert} from '../../../../utils';
import { LeadsServices } from '../../../../services';

const SampleRequestDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const parmas= useParams()
    const [details, setDetails] = useState(null);

    const handleGetDetailsRequest = async () => {
        const getDetailsReq = await LeadsServices.getAuthSampleRequestPage({
            id:parmas.id,
            token: user.access_token
        });
        if(getDetailsReq.status === false){
            ToastAlert.notifyError(getDetailsReq.message);
            setDetails(null);
        }
        else{
            setDetails(getDetailsReq?.data);
        }
    }

    useEffect(() => {
        handleGetDetailsRequest();
    }, []);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Sample Request Details Page'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to Sample Request Details Page in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sample Request Details"}  isBottomAction={false}>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"person name"} 
                                            description={defaults.capitalize(details?.person_name)} 
                                        />
                                         <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"org name"} 
                                            description={defaults.capitalize(details?.org_name)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"email id"} 
                                            description={defaults.capitalize(details?.email_id)} 
                                        />
                                         <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"phone number"} 
                                            description={defaults.capitalize(details?.phone_number)} 
                                        />
                                    </DetailsRow>

                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(details?.description)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"product"} 
                                            description={defaults.capitalize(details?.product?.name)} 
                                        />
                                         <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"sub product"} 
                                            description={defaults.capitalize(details?.sub_product?.name)} 
                                        />
                                    </DetailsRow>
                            </PlainContainer>
                     
                    </Card>
                </PlainContainer>
            </PlainContainer>
        </>
        
    )
}

export default SampleRequestDetailsPage