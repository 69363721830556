import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import StateServices from '../../../services/Location/State';
import { ToastAlert } from '../../../utils';
import Pagination from '../../../components/core/table/pagination';
import { LeadsServices } from '../../../services';

const SampleRequestListPage = ({ user }) => {
    const navigate = useNavigate();

    const [sampleRequestBack, setSampleRequest] = useState([]);
    const [totalSampleRequestCount, setTotalSampleRequestCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetSampleRequest = async () => {
        const getSampleRequestReq = await LeadsServices.getAllSampleRequest({
            token: user.access_token,
            current_page: currentPage
        });
        if(getSampleRequestReq.status === false){
            ToastAlert.notifyError(getSampleRequestReq.message);
            setSampleRequest([]);
            setTotalSampleRequestCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else {
            const SampleRequest = getSampleRequestReq?.data?.items?.map(cb => ({
                ...cb,
                createdAtFormatted: moment(cb.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setSampleRequest(SampleRequest);
            setTotalSampleRequestCount(getSampleRequestReq.data.total_items);
            setTotalPagesCount(getSampleRequestReq.data.total_pages);
            setCurrentPage(getSampleRequestReq.data?.current_page === undefined ? 1 : getSampleRequestReq.data.current_page);;
        }
    }

    useEffect(() => {
        handleGetSampleRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='  Sample Request '
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Requested Samples .'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <StatesListFilters />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"person name"} />
                                    <TableHeadingItem size={2} lable={"Products"} />
                                    <TableHeadingItem size={2} lable={"sub products"} />
                                    <TableHeadingItem size={2} lable={"Received on"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    {/* <TableHeadingItem size={2} lable={"Actions"} /> */}
                                </TableHeading>
                                <TableRows>
                                    {
                                        sampleRequestBack.map((cb, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.person_name} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.product?.name} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.sub_product?.name} />
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={cb?.createdAtFormatted} />
                                                    
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${cb?._id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    {/* <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions> */}
                                                            {/* <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${state.id}`) }>edit</TableButton> */}
                                                            {/* <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteStatesRequest(state.id)}>delete</TableButton> */}
                                                        {/* </TableRowActions>
                                                    </TableRowItem> */}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                            <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                            </PlainContainer>
                            :
                            <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default SampleRequestListPage