import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icons from '../../../constants/icons';
import BasicBigHeader from '../../../components/dashboard/common/PageHeaders/BasicBigHeader';
import ContentWithUserDetails from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/ContentWithUserDetails';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import BasicStats from '../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../components/dashboard/common/BasicStats/BasicStat';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import BasicListItem from '../../../components/dashboard/common/Requests/BasicListItem';
import BasicActivitiesList from '../../../components/dashboard/common/Activities/BasicActivitiesList';
import BasicActivityListItem from '../../../components/dashboard/common/Activities/BasicActivityListItem';
import { StatsServices } from '../../../services';
import { ToastAlert } from '../../../utils';
import moment from 'moment';

const SuperAdminHomePage = ({user}) => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [stats , setStats] = useState([]);
    const [sampleRequest , setSampleRequest] = useState([]);
    const [callbackRequest , setCallbackRequest] = useState([]);
    
    useEffect(() => {
        let nameArray = user.name.split(" ");
        setFirstName(nameArray[0]);
    }, [user]);

    const handleGetThisStatsRequest = async() => {
        const getThisStatReq = await StatsServices.getThisStats({
            token : user.access_token
        })
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setStats([]);
        }
        else{
            setStats(getThisStatReq?.data);
        }
    }

    useEffect(()=>{
        handleGetThisStatsRequest()
    },[])

    useEffect(()=>{
        console.log("")
    },[stats])

    const handleGetRequestedCallbackRequest = async () => {
        const getRequestedCallbackReq = await StatsServices.getRequestedCallback({
            token: user.access_token,
        });

        if(getRequestedCallbackReq.status === false){
            ToastAlert.notifyError(getRequestedCallbackReq.message);
            setCallbackRequest([]);
        }
        else{
            
            const callbackRequest = getRequestedCallbackReq?.data?.map(cr => ({
                ...cr,
                createdAtFormatted : moment(cr.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setCallbackRequest(callbackRequest);
        }
    }

    useEffect(() => {
        handleGetRequestedCallbackRequest();
    },[]);

    const handleGetRequestedSampleRequest = async () => {
        const getRequestedSampleReq = await StatsServices.getRequestedSample({
            token: user.access_token,
        });
        if(getRequestedSampleReq.status === false){
            ToastAlert.notifyError(getRequestedSampleReq.message);
            setSampleRequest([]);
        }
        else{
            const sampleRequest = getRequestedSampleReq?.data?.map(sr => ({
                ...sr,
                createdAtFormatted : moment(sr.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setSampleRequest(sampleRequest);
            console.log("sample back request",sampleRequest)
        }
    }

    useEffect(() => {
        handleGetRequestedSampleRequest();
    },[]);

    return(
        <>
            <BasicBigHeader>
                <ContentWithUserDetails 
                    avatar={'/images/default-avatar.jpg'}
                    heading={`welcome back, ${firstName}!`}
                    descriptionIcon={icons.bellSolid}
                    hasDescriptionIcon={true}
                    description='You have 2 new notifications and 10 pending approvals!'
                />

                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="primary"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.tickCircle}
                    >
                        pending approvals
                    </Button>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.settingsGear}
                    >
                        settings
                    </Button>
                </HeaderActions> */}
            </BasicBigHeader>

            <BasicStats>
                <BasicStat 
                    cardHeading={"Callback Requested"}
                    statCount={stats?.request_callback_count}
                    statLable="No Of Requested Callbacks"
                    cardStatColor="blue"
                    secondaryStatLable="Under Progress"
                    secondaryStatCount={255}
                    statId={`websites`}
                    // headingActionIcon={icons.rightArrow}
                />
                <BasicStat 
                    cardHeading={"Samples Requested"}
                    statCount={stats?.request_sample_count}
                    statLable="No Of Requested Samples"
                    cardStatColor="red"
                    secondaryStatLable="Feature Updates"
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                />
                <BasicStat 
                    cardHeading={"Uniforms"}
                    statCount={stats?.products_count}
                    statLable="No Of uniforms"
                    cardStatColor="yellow"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                />
                <BasicStat 
                    cardHeading={"Pages"}
                    statCount={stats?.pages_count}
                    statLable="No Of Pages"
                    cardStatColor="green"
                    secondaryStatLable="Staff Under Owner"
                    secondaryStatCount={20}
                    statId={`users`}
                />
            </BasicStats>

            <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px'}}>
                <SplitContainer type="full" styles={{margin: '0px', flex: '50%'}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"Requested Callbacks"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent Callback requests"}
                            bottomPrimaryAction={() => navigate("/dashboard/leads/request-for-callback")}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                {
                                    callbackRequest.map((cr)=>(
                                        <BasicListItem 
                                            key={cr._id} 
                                            heading={`${cr?.person_name}(${cr?.org_name})`} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={cr?.phone_number}
                                            primaryInfoIcon={icons.phoneCall}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={cr?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={false}
                                            actionIcon={icons.rightArrow}
                                            action={() => console.log()} 
                                        />
                                    ))
                                }
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        <Card 
                            heading={"Requested Samples"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent Requested Samples"}
                            bottomPrimaryAction={() => navigate("/dashboard/leads/sample-request")}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                {
                                    sampleRequest.map((sr)=>(
                                        <BasicListItem
                                        key={sr?.id}
                                            heading={`${sr?.person_name}(${sr?.org_name})`}
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={`${sr?.product?.name}  (${sr?.sub_product?.name})`}
                                            primaryInfoIcon={icons.sitemap}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={sr?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => navigate(`/dashboard/leads/sample-request/${sr?._id}`)}
                                />
                                    ))
                                }
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                </SplitContainer>
            </PlainContainer>
        </>
    )
}

export default SuperAdminHomePage