import React, { useEffect, useState, forwardRef, useRef, useMemo  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import TextInputRT from '../../../../../components/core/form/TextInputRT';
import TextAreaInput from '../../../../../components/core/form/TextAreaInput';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import { defaults, ToastAlert, functions, globals, validations } from '../../../../../utils';
import { ArticlePageServices, ProductServices } from '../../../../../services';
import CardHeading from '../../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../../components/core/typography/dashboard/CardDescription';
import FileUploadInput from '../../../../../components/core/form/FileUploadInput';
import FormActions from '../../../../../components/form/FormActions';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const AddArticlePage = ({ user }) => {
    const navigate = useNavigate();

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }
    
    const quillRef = useRef();
    
    const imageHandler = async (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
    
        input.onchange = async () => {
            const file = input.files[0];
    
            // file type is only image.
            if (/^image\//.test(file.type)) {
                const data = new FormData();
                data.append("media", file);
        
                try {
                    const saveEditorImageReq = await axios.post(`${defaults.API_BASE_URL}media`, data, config);
                    // notifySuccess(OPENING_CREATED);
                    console.log(saveEditorImageReq);
                    const url = `${defaults.API_BASE_URL}${saveEditorImageReq?.data?.image?.thumbnail?.high_res}`;
                    editor.insertEmbed(editor.getSelection(), "image", url);
                }
                catch (error) {
                    // notifyError(REQUEST_ERROR);
                    console.log(error);
                }
            } else {
                console.warn("You could only upload images.");
            }
        };
    };
    

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [
                    { 'header': [1, 2, 3, 4, 5, 6, false] }
                ],
                ['bold', 'italic', 'underline', "strike"],
                ['image', "link"],
                [
                    { 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }
                ],
                [{ 'align': [] }],
                [
                    { 'color': 
                        ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
                    }
                ]
            ],
            handlers: {
                image: imageHandler
            }
        },
    }), []);

    const [title, setTitle] = useState("");
    const [hasTitleIcon, setHasTitleIcon] = useState(false);
    const [titleIcon, setTitleIcon] = useState("");
    const [titleIconPosition, setTitleIconPosition] = useState("right");
    const [titleIconType, setTitleIconType] = useState("");
    const [hasTitleMessage, setHasTitleMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState("");
    const [titleMessageType, setTitleMessageType] = useState("");

    const [url, setURL] = useState("");
    const [hasURLIcon, setHasURLIcon] = useState(false);
    const [urlIcon, setURLIcon] = useState("");
    const [urlIconPosition, setURLIconPosition] = useState("right");
    const [urlIconType, setURLIconType] = useState("");
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [isURLValid, setIsURLValid] = useState(false);

    const [shortDescription, setShortDescription] = useState("");
    const [hasShortDescriptionIcon, setHasShortDescriptionIcon] = useState(false);
    const [shortDescriptionIcon, setShortDescriptionIcon] = useState("");
    const [shortDescriptionIconPosition, setShortDescriptionIconPosition] = useState("right");
    const [shortDescriptionIconType, setShortDescriptionIconType] = useState("");
    const [hasShortDescriptionMessage, setHasShortDescriptionMessage] = useState(false);
    const [shortDescriptionMessage, setShortDescriptionMessage] = useState("");
    const [shortDescriptionMessageType, setShortDescriptionMessageType] = useState("");

    const [description, setDescription] = useState("");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [selectedImagesMobile, setSelectedImagesMobile] = useState([]);
    const [imageTypeMobile, setImageTypeMobile] = useState("image");
    const [imageIsMultipleMobile, setImageIsMultipleMobile] = useState(false);
    const [imageMaxFileSelectionMobile, setImageMaxFileSelectionMobile] = useState(1);
    const [imagePopUpLableMobile, setImagePopUpLableMobile] = useState("Image");
    const [imageIsErrorMobile, setImageIsErrorMobile] = useState(false);
    const [imageDataMobile, setImageDataMobile] = useState(null);

    const [selectedImagesThumbnail, setSelectedImagesThumbnail] = useState([]);
    const [imageTypeThumbnail, setImageTypeThumbnail] = useState("image");
    const [imageIsMultipleThumbnail, setImageIsMultipleThumbnail] = useState(false);
    const [imageMaxFileSelectionThumbnail, setImageMaxFileSelectionThumbnail] = useState(1);
    const [imagePopUpLableThumbnail, setImagePopUpLableThumbnail] = useState("Image");
    const [imageIsErrorThumbnail, setImageIsErrorThumbnail] = useState(false);
    const [imageDataThumbnail, setImageDataThumbnail] = useState(null);

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const handleImageSelectionMobile = async (e) => {
        if(e.status === true){
            setSelectedImagesMobile([...e.files]);
            setImageIsErrorMobile(false);
        }
        else{
            setSelectedImagesMobile([]);
        }
    }

    const handleImageSelectionThumbnail = async (e) => {
        if(e.status === true){
            setSelectedImagesThumbnail([...e.files]);
            setImageIsErrorThumbnail(false);
        }
        else{
            setSelectedImagesThumbnail([]);
        }
    }

    const pathChanged = async (e) => {
        if(e.length > 0){
            setURL(e);

            const checkPathAvailabilityReq = await ArticlePageServices.checkArticlePathAvailability({
                path: e,
                token: user.access_token
            });
    
            if(checkPathAvailabilityReq.status === false){
                if(checkPathAvailabilityReq.status_code === 403){
                    setHasURLMessage(true);
                    setURLMessage("Page with same path already exists.");
                    setURLMessageType("error");
                    setSubmitButtonDisabled(true);
                    setIsURLValid(false);
                }
                else{
                    ToastAlert.notifyError(checkPathAvailabilityReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setIsURLValid(false);
                }
            }
            else{
                setHasURLMessage(false);
                setURLMessage("");
                setURLMessageType("");
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(false);
                setIsURLValid(true);
            }
        }
        else{
            setURL(e);
            setSubmitButtonDisabled(true);
        }
    }

    const handleAddArticleSubmission = async (e) => {
        e.preventDefault();

        if(isURLValid === false){
            setHasURLIcon(true);
            setURLIcon(icons.wrongCircle);
            setURLIconType("error");
            setHasURLMessage(true);
            setURLMessage("Invalid URL");
            setURLMessageType("error");
        }
        else{
            setHasURLIcon(true);
            setURLIcon(icons.tickCircle);
            setURLIconType("success");
            setHasURLMessage(false);
            setURLMessage("");
            setURLMessageType("");
        }

        if(title.trim() === ""){
            setHasTitleIcon(true);
            setTitleIcon(icons.wrongCircle);
            setTitleIconType("error");
            setHasTitleMessage(true);
            setTitleMessage("Invalid Title");
            setTitleMessageType("error");
        }
        else{
            setHasTitleIcon(true);
            setTitleIcon(icons.tickCircle);
            setTitleIconType("success");
            setHasTitleMessage(false);
            setTitleMessage("");
            setTitleMessageType("");
        }

        if(shortDescription.trim() === ""){
            setHasShortDescriptionIcon(true);
            setShortDescriptionIcon(icons.wrongCircle);
            setShortDescriptionIconType("error");
            setHasShortDescriptionMessage(true);
            setShortDescriptionMessage("Invalid Short Description");
            setShortDescriptionMessageType("error");
        }
        else{
            setHasShortDescriptionIcon(true);
            setShortDescriptionIcon(icons.tickCircle);
            setShortDescriptionIconType("success");
            setHasShortDescriptionMessage(false);
            setShortDescriptionMessage("");
            setShortDescriptionMessageType("");
        }

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        if(selectedImagesMobile.length === 0){
            setImageIsErrorMobile(true);
        }
        else{
            setImageIsErrorMobile(false);
        }

        if(selectedImagesThumbnail.length === 0){
            setImageIsErrorThumbnail(true);
        }
        else{
            setImageIsErrorThumbnail(false);
        }

        const saveArticleReq = await ArticlePageServices.saveArticle({
            title: title,
            url: url,
            short_description: shortDescription,
            description: description,
            cover_image_web: selectedImages[0],
            cover_image_mobile:selectedImagesMobile[0],
            thumbnail:selectedImagesThumbnail[0],
            token: user.access_token
        });

        if(saveArticleReq.status === false){
            ToastAlert.notifyError(saveArticleReq.message);
            setSubmitButtonLoading(false);
        }
        else{
            ToastAlert.notifySuccess(saveArticleReq.message);
            setSubmitButtonLoading(false);
            setSubmitButtonDisabled(true);
            setSubmitButtonBg('success');
            setSubmitButtonHasIcon(true);
            setSubmitButtonLable('Success');
            navigate(-1);
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Add Article'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Add new Article to display in the website'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"Create Article"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddArticleSubmission(e)} noValidate>
                                <div className="cmsForm">
                                   
                                <TextInputRT 
                                        hasLable={true}
                                        lable="Title"
                                        isMandatory={false}
                                        hasIcon={hasTitleIcon}
                                        icon={titleIcon}
                                        iconPosition={titleIconPosition}
                                        iconType={titleIconType}
                                        hasMessage={hasTitleMessage}
                                        message={titleMessage}
                                        messageType={titleIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Page Title"}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </TextInputRT>
 
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="URL"
                                        isMandatory={false}
                                        hasIcon={hasURLIcon}
                                        icon={urlIcon}
                                        iconPosition={urlIconPosition}
                                        iconType={urlIconType}
                                        hasMessage={hasURLMessage}
                                        message={urlMessage}
                                        messageType={urlMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter URL"}
                                            onChange={(e) => pathChanged(e.target.value)}
                                            value={url}
                                        />
                                    </TextInputRT>
                                     <TextAreaInput 
                                        hasLable={true}
                                        lable={"short description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasShortDescriptionMessage}
                                        message={shortDescriptionMessage}
                                        messageType={shortDescriptionMessageType}
                                    >
                                        <textarea 
                                            type="text" 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter Short Description"}
                                            onChange={(e) => setShortDescription(e.target.value)}
                                            rows={3}
                                            value={shortDescription}
                                        >
                                        </textarea>
                                    </TextAreaInput>

                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasDescriptionMessage}
                                        message={descriptionMessage}
                                        messageType={descriptionMessageType}
                                    >
                                        <ReactQuill modules={modules} ref={quillRef} style={{height: '400px', paddingBottom: '45px'}} theme="snow" value={description} onChange={setDescription} />
                                    </TextAreaInput>
                                    

                                    <FileUploadInput 
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />
                                     <FileUploadInput 
                                        data={imageDataMobile}
                                        user={user} 
                                        lable={imagePopUpLableMobile} 
                                        acceptedFileType={imageTypeMobile} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={imageIsMultipleMobile} 
                                        maxSelection={imageMaxFileSelectionMobile} 
                                        isError={imageIsErrorMobile}
                                        action={(e) => handleImageSelectionMobile(e)} 
                                    />
                                     <FileUploadInput 
                                        data={imageDataThumbnail}
                                        user={user} 
                                        lable={imagePopUpLableThumbnail} 
                                        acceptedFileType={imageTypeThumbnail} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={imageIsMultipleThumbnail} 
                                        maxSelection={imageMaxFileSelectionThumbnail} 
                                        isError={imageIsErrorThumbnail}
                                        action={(e) => handleImageSelectionThumbnail(e)} 
                                    />

                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddArticlePage
