import axios from "axios";
import { statusCodes, defaults } from "../../../utils";

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveArticle = async (payload) => {

    if (!payload.token) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });
        return response;
    }

    if (!payload.title) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "title is required.",
        });
        return response;
    }

    if (!payload.url) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "url is required.",
        });
        return response;
    }
    
    if (!payload.description) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "description is required.",
        });
        return response;
    }

    if (!payload.short_description) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "short_description is required.",
        });
        return response;
    }

    if (!payload.cover_image_web) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "cover image web is required.",
        });
        return response;
    }

    if (!payload.cover_image_mobile) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "cover image mobile is required.",
        });
        return response;
    }

    if (!payload.thumbnail) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "thumbnailis required.",
        });
        return response;
    }

    const data = {
        title: payload.title,
        url: payload.url,
        short_description: payload.short_description,
        description: payload.description,
        cover_image_web: payload.cover_image_web,
        cover_image_mobile: payload.cover_image_mobile,
        thumbnail: payload.thumbnail,
    }

    try {
        let headers = await config(payload.token);
        const saveArticleReq = await axios.post(`${defaults.API_BASE_URL}blog`, data, headers);
        if (saveArticleReq.status === statusCodes.CREATED) {
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Article successfully saved.",
                data: saveArticleReq?.data?.data,
            });
            return response;
        } 
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error.",
            });
            return response;
        }
    } 
    catch (error) {
        if (error.response.status === statusCodes.UNAUTHORISED) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
            return response;
        } 
        else if (error.response.status === statusCodes.FORBIDDEN) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
            return response;
        } 
        else if (error.response.status === statusCodes.VALIDATION_FAILED) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
            return response;
        } 
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error.",
            });
            return response;
        }
    }
}

const checkArticlePathAvailability = async (payload) => {
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    if(!payload.path){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Path is required.",
        });

        return response
    }

    let headers = await config(payload.token);

    try{
        const checkCheckPageReq = await axios.get(`${defaults.API_BASE_URL}blog/check-path?path=${payload.path.toLowerCase().trimStart().trimEnd()}`, headers);
        if(checkCheckPageReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Page path available.",
                data: ""
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Page path is required."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "You are not authorised to perform this action.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            console.log(error.response.data.message);
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: error.response.data.message,
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Something went wrong!"
            });
    
            return response
        }
    }
}

const getAllAuthArticle = async (payload) => {

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getAllArticleReq = await axios.get(`${defaults.API_BASE_URL}blog/auth`, headers);
        console.log("services article",getAllArticleReq)
        if(getAllArticleReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Articles successfully recieved.",
                data: getAllArticleReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getThisAuthArticle = async (payload) => {

    if(!payload.article_id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getThisArticleReq = await axios.get(`${defaults.API_BASE_URL}blog/auth/${payload.article_id}`, headers);
        console.log(getThisArticleReq);
        if(getThisArticleReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Products successfully recieved.",
                data: getThisArticleReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const sortActions = async (payload) => {
    if(!payload.blog_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Product ID is required.",
        });

        return response
    }

    if(!payload.items){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "List is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        items: payload.items
    }

    try{
        let headers = await config(payload.token);

        const sortActionsReq = await axios.post(`${defaults.API_BASE_URL}blog/sort/actions/${payload.blog_id}`, data, headers);
        if(sortActionsReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Actions successfully sorted.",
                data: sortActionsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const saveAction = async (payload) => {

    if(!payload.blog_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Article ID is required.",
        });

        return response
    }

    if(!payload.name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Name is required.",
        });

        return response
    }

    if(!payload.action){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Action is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        name: payload.name.trim(),
        action: payload.action.trim()
    }

    try{
        let headers = await config(payload.token);

        const addNewActionReq = await axios.post(`${defaults.API_BASE_URL}blog/action/${payload.blog_id}`, data, headers);
        if(addNewActionReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Action successfully created.",
                data: addNewActionReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const updateAction = async (payload) => {
    if(!payload.blog_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Article ID is required.",
        });

        return response
    }

    if(!payload.action_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Action ID is required.",
        });

        return response
    }

    if(!payload.name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Name is required.",
        });

        return response
    }

    if(!payload.action){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Action is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        name: payload.name.trim(),
        action: payload.action.trim()
    }

    try{
        let headers = await config(payload.token);

        const updateActionReq = await axios.patch(`${defaults.API_BASE_URL}blog/action/${payload.blog_id}/${payload.action_id}`, data, headers);
        if(updateActionReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Action successfully saved.",
                data: updateActionReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const updateArticle = async (payload) => {

    
    if (!payload.id) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "id is required.",
        });
        return response;
    }

    if (!payload.token) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });
        return response;
    }

    if (!payload.title) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "title is required.",
        });
        return response;
    }

    if (!payload.url) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "url is required.",
        });
        return response;
    }
    
    if (!payload.description) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "description is required.",
        });
        return response;
    }

    if (!payload.short_description) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "short_description is required.",
        });
        return response;
    }

    if (!payload.cover_image_web) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "cover image web is required.",
        });
        return response;
    }

    if (!payload.cover_image_mobile) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "cover image mobile is required.",
        });
        return response;
    }

    if (!payload.thumbnail) {
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "thumbnailis required.",
        });
        return response;
    }

    const data = {
        title: payload.title,
        url: payload.url,
        short_description: payload.short_description,
        description: payload.description,
        cover_image_web: payload.cover_image_web,
        cover_image_mobile: payload.cover_image_mobile,
        thumbnail: payload.thumbnail,
    }

    try {
        let headers = await config(payload.token);
        const updateArticleReq = await axios.patch(`${defaults.API_BASE_URL}blog/${payload.id}`, data, headers);
        if (updateArticleReq.status === statusCodes.SUCCESS) {
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Article successfully Updated.",
                data: updateArticleReq?.data?.data,
            });
            return response;
        } 
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error.",
            });
            return response;
        }
    } 
    catch (error) {
        if (error.response.status === statusCodes.UNAUTHORISED) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
            return response;
        } 
        else if (error.response.status === statusCodes.FORBIDDEN) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
            return response;
        } 
        else if (error.response.status === statusCodes.VALIDATION_FAILED) {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
            return response;
        } 
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error.",
            });
            return response;
        }
    }
}

const updateSEO = async (payload) => {
    
    if(!payload.id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Blog ID is required.",
        });

        return response
    }
    
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        title: payload.title,
        slug:payload.url,
        description: payload.description,
        keywords: payload.keywords,
        canonical_url: payload.canonical_url,
        image: payload.image,
        twitter_handler: payload.twitter_handler
    }

    try{
        let headers = await config(payload.token);

        const updateSEOReq = await axios.post(`${defaults.API_BASE_URL}blog/seo/${payload.id}`, data, headers);
        if(updateSEOReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "SEO successfully updated.",
                data: updateSEOReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const updateSitemap = async (payload) => {
    
    if(!payload.id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Blog ID is required.",
        });

        return response
    }

    if(!payload.page){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Page is required.",
        });

        return response
    }

    if(!payload.image){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Image is required.",
        });

        return response
    }
    
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        page: payload.page,
        image: payload.image
    }

    try{
        let headers = await config(payload.token);

        const updateSitemapReq = await axios.post(`${defaults.API_BASE_URL}blog/sitemap/${payload.id}`, data, headers);
        if(updateSitemapReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Sitemap successfully updated.",
                data: updateSitemapReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const deleteArticleAction = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Product ID is required.",
        });

        return response
    }

    if(!payload.article_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Product ID is required.",
        });

        return response
    }
    
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

  

    try{
        let headers = await config(payload.token);

        const deleteArticleActionReq = await axios.delete(`${defaults.API_BASE_URL}blog/action/${payload.article_id}/${payload.id}`,headers);
        if(deleteArticleActionReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Article Action successfully deleted.",
                data: deleteArticleActionReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}



const ArticlePageServices = {
    saveArticle,
    checkArticlePathAvailability,
    getAllAuthArticle,
    getThisAuthArticle,
    sortActions,
    saveAction,
    updateAction,
    updateArticle,
    updateSitemap,
    updateSEO,
    deleteArticleAction
    
}

export default ArticlePageServices