import axios from "axios";
import { statusCodes, defaults } from "../../utils";

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}


const getAuthBrand = async (payload) => {

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    try{
        let headers = await config(payload.token);

        const getAuthBrandReq = await axios.get(`${defaults.API_BASE_URL}default/auth/brand-identity/logo`, headers);
        if(getAuthBrandReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Brand successfully recieved.",
                data: getAuthBrandReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const deleteSocial = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: " ID is required.",
        });

        return response
    }
    
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

  

    try{
        let headers = await config(payload.token);

        const deleteSocialReq = await axios.delete(`${defaults.API_BASE_URL}default/social-media-platform/${payload.id}`,headers);
        if(deleteSocialReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Social successfully deleted.",
                data: deleteSocialReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getAllAuthSocials = async (payload) => {

  if(!payload.token){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });

      return response
  }

  try{
      let headers = await config(payload.token);

      const getAllSocialsReq = await axios.get(`${defaults.API_BASE_URL}default/auth/social-media-platform`, headers);
      if(getAllSocialsReq.status === statusCodes.SUCCESS){
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Socials successfully recieved.",
              data: getAllSocialsReq?.data?.data
          });
  
          return response
      }
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error."
          });
  
          return response
      }
  }
  catch(error){
      if(error.response.status === statusCodes.UNAUTHORISED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.FORBIDDEN){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.VALIDATION_FAILED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
  
          return response
      }
      else{
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error."
          });
  
          return response
      }
  }
}

const sortSocial = async (payload) => {
  if(!payload.items){

      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "List is required.",
      });

      return response
  }

  if(!payload.token){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });

      return response
  }

  const data = {
      items: payload.items
  }

  try{
      let headers = await config(payload.token);

      const sortSocialReq = await axios.post(`${defaults.API_BASE_URL}default/sort/social-media-platform`, data, headers);
      if(sortSocialReq.status === statusCodes.SUCCESS){
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Social successfully sorted.",
              data: sortSocialReq?.data?.data
          });
  
          return response
      }
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error."
          });
  
          return response
      }
  }
  catch(error){
      if(error.response.status === statusCodes.UNAUTHORISED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.FORBIDDEN){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.VALIDATION_FAILED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
  
          return response
      }
      else{
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error."
          });
  
          return response
      }
  }
}

const updateBrand = async (payload) => {

  if(!payload.image){

      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Image is required.",
      });

      return response
  }
  
  if(!payload.token){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });

      return response
  }

  const data = {
      image: payload.image
  }

  try{
      let headers = await config(payload.token);

      const updateBrandReq = await axios.post(`${defaults.API_BASE_URL}default/brand-identity/logo`, data, headers);
      if(updateBrandReq.status === statusCodes.SUCCESS){
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Brand successfully updated.",
              data: updateBrandReq?.data?.data
          });
  
          return response
      }
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error."
          });
  
          return response
      }
  }
  catch(error){
      if(error.response.status === statusCodes.UNAUTHORISED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.FORBIDDEN){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.VALIDATION_FAILED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
  
          return response
      }
      else{
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error."
          });
  
          return response
      }
  }
}
const saveSocial = async (payload) => {

  if (!payload.token) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });
      return response;
  }

  if (!payload.action) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "action is required.",
      });
      return response;
  }

  if (!payload.platform) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "platform is required.",
      });
      return response;
  }

  if (!payload.image) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "image is required.",
      });
      return response;
  }

  const data = {
      action: payload.action,
      lable: payload.platform,
      icon: payload.image,
  }

  try {
      let headers = await config(payload.token);
      const saveSocialReq = await axios.post(`${defaults.API_BASE_URL}default/social-media-platform`, data, headers);
      if (saveSocialReq.status === statusCodes.CREATED) {
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.CREATED,
              message: "Social successfully saved.",
              data: saveSocialReq?.data?.data,
          });
          return response;
      } 
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error.",
          });
          return response;
      }
  } 
  catch (error) {
      if (error.response.status === statusCodes.UNAUTHORISED) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
          return response;
      } 
      else if (error.response.status === statusCodes.FORBIDDEN) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
          return response;
      } 
      else if (error.response.status === statusCodes.VALIDATION_FAILED) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
          return response;
      } 
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error.",
          });
          return response;
      }
  }
}
const getThisAuthSocial = async (payload) => {

  if(!payload.id){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "ID is required.",
      });

      return response
  }

  if(!payload.token){
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });

      return response
  }

  try{
      let headers = await config(payload.token);

      const getThisSocialReq = await axios.get(`${defaults.API_BASE_URL}default/auth/social-media-platform/${payload.id}`, headers);
      console.log(getThisSocialReq);
      if(getThisSocialReq.status === statusCodes.SUCCESS){
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Social successfully recieved.",
              data: getThisSocialReq?.data?.data
          });
  
          return response
      }
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error."
          });
  
          return response
      }
  }
  catch(error){
      if(error.response.status === statusCodes.UNAUTHORISED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.FORBIDDEN){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
  
          return response
      }
      else if(error.response.status === statusCodes.VALIDATION_FAILED){
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
  
          return response
      }
      else{
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error."
          });
  
          return response
      }
  }
}

const updateSocial = async (payload) => {

  if (!payload.token) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "Token is required.",
      });
      return response;
  }

  if (!payload.id) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "id is required.",
      });
      return response;
  }

  if (!payload.platform) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "platform is required.",
      });
      return response;
  }

  if (!payload.action) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "action is required.",
      });
      return response;
  }

  if (!payload.image) {
      const response = defaults.ServiceResponse({
          status: false,
          status_code: statusCodes.VALIDATION_FAILED,
          message: "image is required.",
      });
      return response;
  }

  const data = {
      lable: payload.platform,
      action: payload.action,
      icon: payload.image,
  }

  try {
      let headers = await config(payload.token);
      const updateSocialReq = await axios.patch(`${defaults.API_BASE_URL}default/social-media-platform/${payload.id}`, data, headers);
      if (updateSocialReq.status === statusCodes.SUCCESS) {
          const response = defaults.ServiceResponse({
              status: true,
              status_code: statusCodes.SUCCESS,
              message: "Social successfully updated.",
              data: updateSocialReq?.data?.data,
          });
          return response;
      } 
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to unexpected technical error.",
          });
          return response;
      }
  } 
  catch (error) {
      if (error.response.status === statusCodes.UNAUTHORISED) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.UNAUTHORISED,
              message: "Please provide your token.",
          });
          return response;
      } 
      else if (error.response.status === statusCodes.FORBIDDEN) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.FORBIDDEN,
              message: "User doesn't have required rights to process this request.",
          });
          return response;
      } 
      else if (error.response.status === statusCodes.VALIDATION_FAILED) {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.VALIDATION_FAILED,
              message: "Invalid Inputs",
          });
          return response;
      } 
      else {
          const response = defaults.ServiceResponse({
              status: false,
              status_code: statusCodes.BAD_REQUEST,
              message: "Request failed due to expected technical error.",
          });
          return response;
      }
  }
}


const DefaultsServices = {
    getAuthBrand,
    updateBrand,
    getAllAuthSocials,
    sortSocial,
    saveSocial,
    deleteSocial,
    getThisAuthSocial,
    getThisAuthSocial,
    updateSocial
}

export default DefaultsServices