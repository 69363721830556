import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import SelectInput from '../../../core/form/SelectInput';
import RadioButtonGroup from '../../../core/form/RadioButtonGroup';
import RadioButtonInput from '../../../core/form/RadioButtonInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { HeaderServices, PageServices } from '../../../../services';

const AddSubParentNavigationItemPopUp = ({ user, concludeAddSubParentNavigationItemPopUp }) => {

    const [parent, setParent] = useState("");
    const [hasParentMessage, setHasParentMessage] = useState(false);
    const [parentMessage, setParentMessage] = useState("");
    const [parentMessageType, setParentMessageType] = useState("");

    const [lable, setLable] = useState("");
    const [hasLableMessage, setHasLableMessage] = useState(false);
    const [lableMessage, setLableMessage] = useState("");
    const [lableMessageType, setLableMessageType] = useState("");

    const [gotoType, setGotoType] = useState("PAGE");

    const [pages, setPages] = useState([]);

    const [gotoPage, setGotoPage] = useState(0);
    const [hasGotoPageMessage, setHasGotoPageMessage] = useState(false);
    const [gotoPageMessage, setGotoPageMessage] = useState("");
    const [gotoPageMessageType, setGotoPageMessageType] = useState("");

    const [gotoURL, setGotoURL] = useState("");
    const [hasGotoURLMessage, setHasGotoURLMessage] = useState(false);
    const [gotoURLMessage, setGotoURLMessage] = useState("");
    const [gotoURLMessageType, setGotoURLMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const [parents ,setParents] =  useState([])


    const handleGetParentsRequest = async () => {
        const getParentsReq = await HeaderServices.getAllParentItems({
            token: user.access_token,
        });

        if(getParentsReq.status === false){
            ToastAlert.notifyError(getParentsReq.message);
            setParents([]);
           
        }
        else{
            console.log("parents view",getParentsReq?.data)
            setParents(getParentsReq.data);
           
        }
    }

    useEffect(()=>{
        handleGetParentsRequest()
    },[])

    const handleGetPagesRequest = async () => {
        const getPagesReq = await PageServices.getAllActiveAuthPages({
            token: user.access_token,
        });

        console.log(getPagesReq);

        if(getPagesReq.status === false){
            ToastAlert.notifyError(getPagesReq.message);
            setPages([]);
        }
        else{
            if(getPagesReq.data.length > 0){
                setPages(getPagesReq.data);
            }
            else{
                setPages([]);
            }
        }
    }

    useEffect(() => {
        handleGetPagesRequest();
    }, []);

    const handleSubmitAddSubHeaderAction = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(lable === ""){
            setHasLableMessage(true);
            setLableMessage("Invalid Lable");
            setLableMessageType("error");
        }
        else{
            setHasLableMessage(false);
            setLableMessage("");
            setLableMessageType("");
        }
        if(parent === ""){
            setHasParentMessage(true);
            setParentMessage("Invalid Lable");
            setParentMessageType("error");
        }
        else{
            setHasParentMessage(false);
            setParentMessage("");
            setParentMessageType("");
        }
        

        if(gotoType === "PAGE" && gotoPage == 0){
            setHasGotoPageMessage(true);
            setGotoPageMessage("Page selection is required");
            setGotoPageMessageType("error");
        }
        else{
            setHasGotoPageMessage(false);
            setGotoPageMessage("");
            setGotoPageMessageType("");
        }

        if(gotoURL === "URL" && gotoURL.trim() === ""){
            setHasGotoURLMessage(true);
            setGotoURLMessage("Invalid URL");
            setGotoURLMessageType("error");
        }
        else{
            setHasGotoURLMessage(false);
            setGotoURLMessage("");
            setGotoURLMessageType("");
        }

        if(lable === "" || parent ==="" ||  gotoType === ""){

            console.log("here");
            setSubmitButtonLoading(false);
            return
        }
        else{

            if(gotoType === "PAGE" && gotoPage == 0){
                setSubmitButtonLoading(false);
                return
            }
            else if(gotoType === "URL" && gotoURL.trim() === ""){
                setSubmitButtonLoading(false);
                return
            }

            const data ={
                parent_id :parent,
                lable: lable,
                goto_type: gotoType,
                goto: gotoType === "PAGE" ? gotoPage : gotoURL,
                token: user.access_token
            }

            const saveParentNavigationItemReq = await HeaderServices.saveSubParentNavigationItem(data);

            console.log(saveParentNavigationItemReq);

            if(saveParentNavigationItemReq.status === false){
                ToastAlert.notifyError(saveParentNavigationItemReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveParentNavigationItemReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeAddSubParentNavigationItemPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleSubmitAddSubHeaderAction(e)} noValidate>
                <div className="cmsForm">
                     <SelectInput 
                                        hasLable={true}
                                        lable="Parent items"
                                        isMandatory={true}
                                        hasMessage={hasParentMessage}
                                        message={parentMessage}
                                        messageType={parentMessageType}
                                    >
                                        <select className="cmsFormStepInputSelect" value={parent} onChange={(e) => setParent(e.target.value)}>
                                            <option value={0} disabled={false}>Select Parent items</option>
                                            {
                                                parents.map((pt) => {
                                                    return (
                                                        <option key={pt.id} value={pt.id}>{pt?.lable}</option>
                                                    )
                                                })
                                            }
                                        </select>
                     </SelectInput>
                    <TextInputRT 
                        hasLable={inputs.primaryNavigationItemLable.hasLable}
                        lable={inputs.primaryNavigationItemLable.lable}
                        isMandatory={inputs.primaryNavigationItemLable.is_mandatory}
                        hasMessage={hasLableMessage}
                        message={lableMessage}  
                        messageType={lableMessageType}
                        isLimited={inputs.primaryNavigationItemLable.is_limited}
                        limit={inputs.primaryNavigationItemLable.max_characters}
                        value={lable}
                    >
                        <input 
                            type={inputs.primaryNavigationItemLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.primaryNavigationItemLable.placeholder}
                            onChange={(e) => setLable(e.target.value)}
                            value={lable}
                        />
                    </TextInputRT>

                    <RadioButtonGroup lable="Go To Type" isMandatory={true} isError={false} error={""}>
                        <RadioButtonInput lable="page" name="gotoType" value="PAGE" checked={gotoType === "PAGE" ? true : false} action={(data) => setGotoType(data)} />
                        <RadioButtonInput lable="URL" name="gotoType" value="URL" checked={gotoType === "URL" ? true : false} action={(data) => setGotoType(data)} />
                    </RadioButtonGroup>

                    {
                        gotoType === "PAGE"
                        ?
                        <>
                            <SelectInput 
                                hasLable={inputs.primaryNavigationItemGotoPage.hasLable}
                                lable={inputs.primaryNavigationItemGotoPage.lable}
                                isMandatory={inputs.primaryNavigationItemGotoPage.is_mandatory}
                                hasMessage={hasGotoPageMessage}
                                message={gotoPageMessage}
                                messageType={gotoPageMessageType}
                            >
                                <select className="cmsFormStepInputSelect" value={gotoPage} onChange={(e) => setGotoPage(e.target.value)}>
                                    <option value={inputs.primaryNavigationItemGotoPage.value} disabled={true}>{inputs.primaryNavigationItemGotoPage.placeholder}</option>
                                    {
                                        pages.map((page, i) => {
                                            return (
                                                <option key={i} value={page.id}>{page?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </SelectInput>
                        </>
                        :
                        gotoType === "URL"
                        ?
                        <>
                            <TextInputRT 
                                hasLable={inputs.primaryNavigationItemGotoURL.hasLable}
                                lable={inputs.primaryNavigationItemGotoURL.lable}
                                isMandatory={inputs.primaryNavigationItemGotoURL.is_mandatory}
                                hasMessage={hasGotoURLMessage}
                                message={gotoURLMessage}
                                messageType={gotoURLMessageType}
                                isLimited={inputs.primaryNavigationItemGotoURL.is_limited}
                                limit={inputs.primaryNavigationItemGotoURL.max_characters}
                                value={gotoURL}
                            >
                                <input 
                                    type={inputs.primaryNavigationItemGotoURL.type} 
                                    className="cmsFormStepInputText" 
                                    placeholder={inputs.primaryNavigationItemGotoURL.placeholder}
                                    onChange={(e) => setGotoURL(e.target.value)}
                                    value={gotoURL}
                                />
                            </TextInputRT>
                        </>
                        :
                        <></>
                    }
                    

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeAddSubParentNavigationItemPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default AddSubParentNavigationItemPopUp