import React from 'react'
import { motion } from 'framer-motion';
import { useState } from 'react';
import PopUp from '../../../../../../components/core/popup';
import UpdateFilePopUp from '../../../../../../components/dashboard/popups/UpdateFilePopUp';

const SidebarFileDetails = ({filename, details, type="file", variants, action=()=>console.log(''), children , user}) => {

    const [showUpdateFilesPopUp , setShowUpdateFilesPopUp] = useState(false)
    // const [filesToUpdate, setFilesToToUpdate] = useState(null);

    // const handleShowUpdateFilesPopUp = async (data) => {
    //     console.log(data);
    //     setFilesToToUpdate(data);
    //     setShowUpdateFilesPopUp(true);
    // }
    const handleConcludeUpdateFilesPopUp = async (e) =>{
         if(e.status === false){
            setShowUpdateFilesPopUp(false)
         }
            else{
                setShowUpdateFilesPopUp(false);
            }
    }

    return (
        <motion.div className="fileManagerSidebarDetails" variants={variants}>
            <div className="fileManagerSidebarDetailsNameAction">
                <div className="fileManagerSidebarDetailsName">
                    <p className="fileManagerSidebarDetailsNameTxt">{filename}</p>
                </div>
                <div className="fileManagerSidebarDetailsAction" onClick={action}>
                    <p className="ffileManagerSidebarDetailsActionTxt" onClick={()=>setShowUpdateFilesPopUp(true)}>Edit Meta Data</p>
                </div>
            </div>
            <div className="fileManagerSidebarDetailsLableSteps">
                <div className="fileManagerSidebarDetailsLable">
                    <p className="fileManagerSidebarDetailsLableTxt">
                        {type === "file" ? "file details" : "folder details"}
                    </p>
                </div>
                <div className="fileManagerSidebarDetailsStepContainer">
                    {children}
                </div>
            </div>
            {
				showUpdateFilesPopUp === true 
				? 
				<PopUp width="max" hasBottomPadding={true} heading="Update Files Details" closePopUp={() => handleConcludeUpdateFilesPopUp({ status: false })}>
					<UpdateFilePopUp user={user} file_details={details} filename={filename} concludeUpdateFilePopUp={(e) => handleConcludeUpdateFilesPopUp(e) } />
				</PopUp>
			:
				<></>
			}
        </motion.div>
        
    )
}

export default SidebarFileDetails