import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import SplitContainer from '../../components/core/containers/SplitContainer';
import Card from '../../components/core/dashboard/Card';
import DetailsRow from '../../components/core/containers/DetailsRow';
import DetailsItem from '../../components/core/containers/DetailsItem';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../utils';
import { ProductServices, UniformsPageServices } from '../../services';
import PopUp from '../../components/core/popup';
import NoDataContainer from '../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../components/core/containers/DetailsItemWithPreview';
import UpdateUniformsPageSitemapPopUp from '../../components/dashboard/popups/Pages/UniformsPage/UpdateUniformsPageSitemapPopUp';
import UpdateDefaultsPageBrandPopUp from '../../components/dashboard/popups/Pages/Defaults/UpdateDefaultsPageBrandPopUp';
import DefaultsServices from '../../services/Defaults';

const DefaultsPage = ({ user }) => {
	const navigate = useNavigate();

	

	const [brand, setBrand] = useState(null);

	const handleGetBrandRequest = async () => {
		const getBrandReq = await DefaultsServices.getAuthBrand({
			token: user.access_token,
		});

		if (getBrandReq.status === false) {
			ToastAlert.notifyError(getBrandReq.message);
			setBrand(null);
		} 
		else {
			console.log("brand details",getBrandReq?.data?.logo);
			setBrand(getBrandReq?.data?.logo);
		}
	};

	useEffect(() => {
		handleGetBrandRequest();
	}, []);

  	const [showUpdateBrandPopUp, setShowUpdateBrandPopUp] = useState(false);

    const handleConcludeUpdateBrandPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateBrandPopUp(false);
        }
        else{
            setShowUpdateBrandPopUp(false);
            handleGetBrandRequest();
        }
    }

    const [socials, setSocials] = useState([]);

    const handleGetSocialRequest = async () => {
        const getSocialReq = await DefaultsServices.getAllAuthSocials({
            token: user.access_token
        });

        if(getSocialReq.status === false){
            ToastAlert.notifyError(getSocialReq.message);
            setSocials([]);
        }
        else{
            console.log("socials",getSocialReq.data);
            setSocials(getSocialReq.data);
        }
    }

    useEffect(() => {
        handleGetSocialRequest();
    }, []);

	const handleDeleteSocial = async (id) => {
		console.log("del id",id)
        const deleteSocialReq = await DefaultsServices.deleteSocial({
            id:id,
            token: user.access_token
        });

        if(deleteSocialReq.status === false){
            ToastAlert.notifyError(deleteSocialReq.message);
            setSocials([]);
        }
        else{
            ToastAlert.notifySuccess(deleteSocialReq.message);
			handleGetSocialRequest()
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);
    
    const SortableItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
			{/* <TableRowItem size={4} isCenter={false} hasPreview={false} lable={value?.icon} /> */}
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'icon'} previewItem={`${value?.icon}`} lable={value?.platform_name} />
			<TableRowItem size={2} isCenter={false} hasPreview={false} lable={`${value.created_by.first_name} (${value.created_by.role === "SUPER_ADMIN" ? "super admin" : "admin"})`} />
            <TableRowItem size={0} isCenter={true} hasChildren={true}>
                <TableRowIcons>
					<a href={value?.action} target="_blank">
						<TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
					</a>
                </TableRowIcons>
            </TableRowItem>
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={()=>navigate(`edit/${value?.id}`)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteSocial(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
      
    const SortableContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const handleSaveSortedList = async (list) => {

        const sortSocialReq = await DefaultsServices.sortSocial({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortSocialReq.status === false){
            ToastAlert.notifyError(sortSocialReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortSocialReq.message);
            handleGetSocialRequest();
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(socials, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setSocials([...arrayMove]);
    }


	return (
		<>
			<DefaultHeader>
				<DefaultContent
				heading="Defaults Page"
				descriptionIcon={""}
				hasDescriptionIcon={false}
				description="Manage everything related to Defaults page in this page"
				/>
				{/* <HeaderActions>
							<Button 
								type="button"
								bgType="fill"
								width="auto"
								bg="dashboard"
								borderRadius="full"
								hasIcon={true}
								iconPosition="left"
								icon={icons.add}
								action={()=> navigate('add') }
							>
								Add New
							</Button>
						</HeaderActions> */}
			</DefaultHeader>

			<PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
				<PlainContainer>
					<Card hasHeading={true} heading={"Brand Identity"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateBrandPopUp(true)} isBottomAction={false}>
						{
							brand === null
							?
							<>
								<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
									<NoDataContainer
										icon={icons.sad}
										heading={"No Brand Details found!"}
										description={"Update Brand details by clicking on the below button."}
										hasSectionAction={false}
										sectionAction={() => alert("section add")}
										hasActions={true}
										hasPrimaryAction={true}
										primaryActionLable={"update"}
										primaryAction={() => setShowUpdateBrandPopUp(true)}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
										secondaryAction={() => handleGetBrandRequest("refresh")}
									/>
								</PlainContainer>
							</>
							:
							<PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
								{
									brand?.square != undefined
									?
									<DetailsRow>
										<DetailsItemWithPreview 
											lable={`Image`}
											preview={`${globals.API_BASE_URL}${brand?.square?.image_url?.full?.high_res}`}
											previewType={"image"}
											fileName={brand?.square?.name?.original}
											fileExtension={brand?.square?.extension?.original}
											fileSize={brand?.square?.size?.current}
										/>
									</DetailsRow>
									:
									<></>
								}
							</PlainContainer>
						}
					</Card>
				</PlainContainer>

				<PlainContainer styles={{marginTop: "25px"}}>
					<Card hasHeading={true} heading={"Social media platforms"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("add")} isBottomAction={false}>
						<PlainContainer type='full' styles={{marginBottom: '25px'}}>
							<Table>
								<TableHeading>
									<TableHeadingItem size={4} lable={"patform"} />
									<TableHeadingItem size={2} lable={"created by"} />
									<TableHeadingItem size={0} lable={""} />
									<TableHeadingItem size={2} lable={"Actions"} />
								</TableHeading>

								<SortableContainer onSortEnd={handleSortEnd} useDragHandle>
									{socials.map((item, index) => (
										<SortableItem key={`item-${index}`} index={index} value={item} />
									))}
								</SortableContainer>
							</Table>
						</PlainContainer>
					</Card>
				</PlainContainer>
			</PlainContainer>
			
			{
				showUpdateBrandPopUp === true 
				? 
				<PopUp width="max" hasBottomPadding={true} heading="Update brand identity" closePopUp={() => handleConcludeUpdateBrandPopUp({ status: false })}>
					<UpdateDefaultsPageBrandPopUp user={user} brand={brand} concludeUpdateBrandPopUp={(e) => handleConcludeUpdateBrandPopUp(e) } />
				</PopUp>
			:
				<></>
			}
		</>
	);
};

export default DefaultsPage;
